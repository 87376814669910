<template>
  <div class="passcode-container">
    <v-row>
      <v-col cols="12" class="pt-6">
        <h1 class="qj-h1 mb-2" v-if="userInfo.customer_type == 'PERSONAL'">
          {{ userInfo.name }},
        </h1>
        <h1 class="qj-h1 mb-2" v-else>Olá,</h1>
        <p class="mb-0 qj-p">
          Nos informe o seu
          <b>código de verificação</b> que enviamos por
          <b>{{ channel ?? "SMS" }}</b> para o seu celular.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <InputVerification
          ref="inputVerification"
          :type="'PASSCODE'"
          @validation="send($event.validation, $event.payload)"
          @sending="sending = $event"
          @alert="alert = $event"
        />
      </v-col>
    </v-row>

    <div v-show="sending" class="loading-container">
      <v-progress-linear
        indeterminate
        rounded
        color="green"
        height="8"
      ></v-progress-linear>
    </div>
    <v-row>
      <v-col cols="12" class="pt-2 shrink text-center mt-1">
        <v-btn
          :disabled="!canSubmitCode"
          @click="resendPasscode()"
          class="qj-btn qj-bg-secondary-light"
          id="btn__receive__sms_error"
        >
          Não recebi o código
        </v-btn>
        <div class="mt-2 mb-2">
          <span class="text-body-2" v-show="timeToResend > 0">
            Você poderá reenviar o código em {{ countdown }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-alert
      :value="alert"
      color="red"
      text
      transition="scale-transition text-center"
      class="qj-alert-error"
    >
      Código de verificação é inválido. Verifique e tente novamente.
    </v-alert>
    <div v-show="codeResent" class="passcode-sent qj-bg-primary mt-2">
      Um novo código foi enviado
    </div>
    <v-spacer></v-spacer>
    <qj-footer :fixed="false" @send="send" view="passcode" />
    <DialogLoadingDebts
      :dialogLoadingDebts="dialog"
      @closeDialogLoadingDebts="close"
      @policy="$emit('policy', $event)"
      @debitsLoaded="$emit('debitsLoaded', $event)"
    />
  </div>
</template>

<script>
import Authentication from "./authentication";
import store from "@/store";
import { mapActions, mapMutations } from "vuex";
import environment from "@/assets/js/environment";
import InputVerification from "@/components/InputVerification";
import DialogLoadingDebts from "../components/modals/DialogLoadingDebts";
import { createTagManagerEvent } from "@/utils";

export default {
  components: {
    InputVerification,
    DialogLoadingDebts,
  },
  data: () => ({
    userInfo: store.getters["authentication/getUserInfo"],
    dialog: false,
    sending: false,
    codeResent: false,
    startTimer: "",
    alert: false,
    channel: store.getters["authentication/getSendPasscodeChannel"],
    timeToResend: null,
  }),
  computed: {
    countdown() {
      const minutes = Math.floor(this.timeToResend / 60);
      const seconds = this.timeToResend % 60;
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    },
    canSubmitCode() {
      return !this.sending && this.timeToResend <= 0;
    },
  },
  beforeRouteEnter(to, from, next) {
    let user = store.getters["authentication/getUserInfo"];

    if (
      store.getters["authentication/getTokenPhone"] &&
      !from.meta.requiresAuth &&
      !user?.id
    ) {
      next();
    } else {
      next({ name: "Authentication" });
    }
  },
  beforeCreate() {
    this.$emit("loading", true);
  },
  created() {
    this.timeToResend = 120;
    this.startCountdown();
    setTimeout(() => this.$emit("loading", false), 300);
  },
  watch: {
    sending() {
      if (this.sending) this.codeResent = false;
    },
  },
  methods: {
    ...mapActions("authentication", ["validateChallenge"]),
    ...mapMutations("authentication", {
      setChallengeResultObject: "setChallengeResultObject",
      setUserInfo: "setUserInfo",
    }),
    ...mapActions("authentication", {
      setCookie: "setAuthTokenToCookie",
    }),

    startCountdown() {
      const interval = setInterval(() => {
        if (this.timeToResend > 0) {
          this.timeToResend--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },

    send(validation, payload) {
      if (validation) {
        let resultTokenObject = this.$jwt.decode(
          store.getters["authentication/getToken"]
        );

        if (resultTokenObject.token_type == "AUTH") {
          this.setUserInfo(resultTokenObject.customer);
          this.setCookie({ payload: null, vm: this });

          this.createTagManagerEvent({ event: "Login" });

          this.dialog = true;
        }
        if (resultTokenObject.token_type == "PRE_AUTH") {
          this.setChallengeResultObject(
            this.$jwt.decode(
              store.getters["authentication/getChallengeResultToken"]
            )
          );
          Authentication.resolveChallenge(
            store.getters["authentication/getChallengeResultObject"].challenge,
            payload,
            this.$route.query.continue
          );
        }
      }
    },
    resendPasscode() {
      createTagManagerEvent({
        event: "loginClickedVerificationCodeNotReceived",
        waitingTime: (Math.round(performance.now()) - this.startTimer) / 1000,
      });

      this.validateChallenge(this.$route.params)
        .then((response) => {
          if (response?.toString().indexOf("401") > -1) return;

          this.timeToResend = 120;
          this.startCountdown();

          this.$refs.inputVerification.clearAlerts();
          setTimeout(() => (this.codeResent = true), 300);

          this.setChallengeResultObject(
            this.$jwt.decode(
              store.getters["authentication/getChallengeResultToken"]
            )
          );
        })
        .catch(() => {
          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        });
    },
    close() {
      this.dialog = false;
      this.$router.push({ name: "Deals" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.invalid-passcode-message {
  background: #ffdbdb;
  color: #ff5050;
  text-align: center;
  border-radius: 7px;
  font-weight: 800;
  padding: 6px 40px;
  font-size: 12px;
}

.passcode-sent {
  color: white;
  text-align: center;
  border-radius: 7px;
  font-weight: 800;
  padding: 6px 40px;
  font-size: 12px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: textfield;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.passcode-container {
  max-width: 960px;
  > div {
    flex-grow: 0;
  }
}

input:hover {
  cursor: pointer;
}

.input-number {
  color: transparent !important;
  text-shadow: 0px 0px 1px #000;
  background-color: #f0f0f0;
  margin: 0;
  appearance: textfield;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

@media (max-width: 500px) {
  .numbers-container {
    margin: 20px 20px !important;
  }
}

.numbers-container {
  display: flex;
  justify-content: center;
  margin: 16px 0;

  input {
    margin: 0 12px;
    width: 50px;
    height: 55px;
    text-align: center;
    font-weight: 800;
    border-radius: 7px;
  }
}
.image-container {
  display: flex;
  justify-content: space-around;
  img {
    width: 90px;
    height: 90px;
  }
}
.text-1 {
  display: flex;
  justify-content: center;
  font-weight: 800;
  margin-top: 20px;
  text-align: center;
}
.text-2 {
  display: block;
  text-align: center;
  font-weight: 400;
  margin: 20px 20px;
}

.loading-container {
  margin: -8px auto 16px;
  max-width: 275px;
}

@media (min-width: 600px) {
  .image-container {
    margin-top: 20px;
  }
}
</style>
