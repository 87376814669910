<template>
  <v-col cols="12" class="pt-0">
    <ul class="qj-list-green">
      <div v-if="showProductAndContract">
        <li class="justify-space-between mt-0">
          <p class="qj-p mb-0">
            {{
              deal?.operations.length > 1
                ? "Contratos renegociados"
                : "Contrato renegociado"
            }}
          </p>
        </li>
        <li
          class="justify-space-between"
          v-for="contract in deal?.operations"
          :key="contract.external_ref"
        >
          <p class="qj-p mb-0">
            {{ contract.product }}
          </p>
          <div class="d-flex flex-column align-end">
            <b>{{ contract.external_ref }}</b>
          </div>
        </li>
        <v-divider v-if="proposal.limite_anterior >= 0 || 
                        proposal.valor_limite_promessa >= 0 || 
                        proposal.valor_limite_pagamento >= 0 || 
                        proposal.descricao_status_limite" class="mt-3">
        </v-divider>
      </div>

      <div>
        <li class="justify-space-between" v-if="proposal.limite_anterior >= 0">
          <p class="qj-p mb-0">
            Limite atual
          </p>
          <b class="mb-0">{{ proposal.limite_anterior | money }}</b>
        </li>
        <li class="justify-space-between" v-if="proposal.valor_limite_promessa >= 0">
          <p class="qj-p mb-0">
            Limite na contratação
          </p>
          <b class="mb-0">{{ proposal.valor_limite_promessa | money }}</b>
        </li>
        <li class="justify-space-between" v-if="proposal.valor_limite_pagamento >= 0">
          <p class="qj-p mb-0">
            Limite apos pagamento da 1ª parcela
          </p>
          <b class="mb-0">{{ proposal.valor_limite_pagamento | money }}</b>
        </li>
        <li class="justify-space-between" v-if="proposal.descricao_status_limite">
          <p class="qj-p mb-0">
            Nova situação do limite
          </p>
          <b class="mb-0">{{ proposal.descricao_status_limite }}</b>
        </li>
        <v-divider class="mt-3"></v-divider>
      </div>

      <li
        class="justify-space-between"
        :class="!showProductAndContract ? 'mt-0' : ''"
        v-if="!propsRegularizeDetails"
      >
        <p class="qj-p mb-0">Valor total da dívida</p>
        <b>{{ proposal.operations_value | money }}</b>
      </li>

      <!-- Desconto -->
      <li class="justify-space-between" v-if="proposal.discount">
        <p class="qj-p mb-0">Economia</p>
        <b class="color-blue">-{{ proposal.discount | money }}</b>
      </li>
      <li
        class="justify-space-between"
        v-if="(totalAmountToPay && proposal.discount) || proposal.total_payment"
      >
        <p class="qj-p mb-0">Valor a pagar</p>
        <b>{{ totalAmountToPay | money }}</b>
      </li>

      <!-- Parcelas -->
      <!-- <li class="justify-space-between" v-if="isInstallmentsCountVisible">
        <p class="qj-p mb-0">Número de parcelas</p>
        <b>{{ proposal.installments_count_1 }} vezes</b>
      </li> -->
      <!-- <li
        class="justify-space-between"
        v-if="
          proposal.down_payment > 0 && proposal.payment_type == 'INSTALLMENT'
        "
      >
        <p class="qj-p mb-0">Valor da entrada</p>
        <b class="mb-0">{{ proposal.down_payment | money }}</b>
      </li> -->
      <!-- <li class="justify-space-between" v-if="isInstallmentVisible">
        <p class="qj-p mb-0">Valor das parcelas</p>
        <b class="mb-0">{{ proposal.installment_1 | money }}</b>
      </li> -->
      <li v-if="proposal.iof" class="justify-space-between">
        <p class="qj-p mb-0">
          I.O.F. total<v-icon class="qj-icon-list" @click="dialogIOF = true"
            >mdi-help-circle-outline</v-icon
          >
        </p>
        <b>{{ proposal.iof | money }}</b>
      </li>
      <!-- CET mês -->
      <li class="justify-space-between" v-if="proposal.cet_month">
        <p class="qj-p mb-0">
          C.E.T. mês<v-icon class="qj-icon-list" @click="dialogCET = true">
            mdi-help-circle-outline</v-icon
          >
        </p>
        <b class="mb-0">{{ proposal.cet_month | percent }}</b>
      </li>
      <!-- CET ano -->
      <li class="justify-space-between" v-if="proposal.cet_year">
        <p class="qj-p mb-0">
          C.E.T. ano<v-icon class="qj-icon-list" @click="dialogCET = true">
            mdi-help-circle-outline</v-icon
          >
        </p>
        <b class="mb-0">{{ proposal.cet_year | percent }}</b>
      </li>
      <!-- Juros -->
      <li class="justify-space-between" v-if="proposal.monthly_interest_rate">
        <p class="qj-p mb-0">% juros ao mês</p>
        <b class="mb-0">
          {{ proposal.monthly_interest_rate | percent }}
        </b>
      </li>
      <li class="justify-space-between" v-else-if="proposal.interest_rate">
        <p class="qj-p mb-0">% juros ao mês</p>
        <b class="mb-0">
          {{ proposal.interest_rate | percent }}
        </b>
      </li>
      <li class="justify-space-between" v-if="proposal.year_interest_rate">
        <p class="qj-p mb-0">% juros anual</p>
        <b class="mb-0">
          {{ proposal.year_interest_rate | percent }}
        </b>
      </li>
      <li
        class="justify-space-between"
        v-if="proposal.total_payment_after_due_date"
      >
        <p class="qj-p mb-0">Parcela após o vencimento</p>
        <b class="mb-0">
          {{ proposal.total_payment_after_due_date | money }}
        </b>
      </li>
      <template v-if="negotiation?.prePaymentCreditCard">
        <li class="justify-space-between">
          <p class="qj-p mb-0">Valor a ser pago</p>
          <b class="mb-0">
            {{
              proposal.total_amount ??
              (proposal.installments_count_1 * proposal.installment_1) | money
            }}
          </b>
        </li>
        <li class="justify-space-between">
          <p class="qj-p mb-0">Forma de pagamento</p>
          <b class="mb-0">
            {{ paymentType().first }}
          </b>
        </li>
        <li class="justify-space-between">
          <p class="qj-p mb-0">Cartão de crédito</p>
          <b class="mb-0">
            {{ negotiation?.prePaymentCreditCard?.card_number | creditCard }}
          </b>
        </li>
      </template>
      <div v-if="showPaymentsMethods()">
        <li
          class="justify-space-between"
          v-if="proposal.payment_type == 'IN_CASH' && !propsRegularizeDetails"
        >
          <p class="qj-p mb-0">Método de pagamento</p>
          <b class="mb-0">
            {{ paymentType().first }}
          </b>
        </li>
        <li
          class="justify-space-between"
          v-if="proposal.payment_type == 'INSTALLMENT'"
        >
          <p class="qj-p mb-0">1ª parcela</p>
          <b class="mb-0">
            {{ paymentType().first }}
          </b>
        </li>
        <li
          class="justify-space-between"
          v-if="proposal.payment_type == 'INSTALLMENT'"
        >
          <p class="qj-p mb-0">Demais pagamentos</p>
          <b class="mb-0">
            {{ paymentType().others }}
          </b>
        </li>
      </div>

      <!-- Informações adicionais -->
      <div v-if="extraInfosArray">
        <li
          v-for="extraInfo in extraInfosArray"
          :key="extraInfo.name"
          class="justify-space-between"
        >
          <p class="qj-p mb-0">{{ extraInfo.name || "" }}</p>
          <b>
            {{ extraInfo.value || 0 }}
          </b>
        </li>
      </div>

      <li
        class="justify-space-between"
        v-if="propsRegularizeDetails?.original_debt_value"
      >
        <p class="qj-p mb-0">Valor Original</p>
        <b class="mb-0">
          {{ propsRegularizeDetails.original_debt_value | money }}
        </b>
      </li>

      <li
        class="justify-space-between"
        v-if="propsRegularizeDetails?.discount_rate >= 0"
      >
        <p class="qj-p mb-0">Desconto das Parcelas</p>
        <b class="mb-0">
          {{ propsRegularizeDetails.discount_rate | percent }}
        </b>
      </li>
    </ul>
    <!-- Dialog IOF -->
    <DialogIOF :dialog="dialogIOF" @close="dialogIOF = false" />
    <!-- Dialog CET -->
    <DialogCET :dialog="dialogCET" @close="dialogCET = false" />
  </v-col>
</template>

<script>
import Proposal from "@/modules/proposal";
import store from "@/store";
import { CreditorId } from "@/constants";
import util from "@/assets/js/util";
import serviceUtil from "@/services/util";
import DialogCET from "@/views/negotiation/components/DialogCET.vue";
import DialogIOF from "@/views/negotiation/components/DialogIOF.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    DialogCET,
    DialogIOF,
  },
  props: {
    proposal: {
      default: null,
    },
    deal: {
      default: null,
    },
    callSumary: {
      default: true,
    },
    propsExtraProposalInfos: {
      type: Array,
      default() {
        return [];
      },
    },
    propsRegularizeDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      extraProposalInfos: [],
      dialogCET: false,
      dialogIOF: false,
    };
  },
  async mounted() {
    let configPrefix = util.getPrefixByProposal(this.proposal.id);
    let url = util.getConfig()[configPrefix + "proposal_summary_url"];
    if (url && this.callSumary) {
      await serviceUtil
        .generic(
          url,
          "post",
          {
            proposal_id: this.proposal.id,
            due_date: this.$route.params.date,
          },
          {
            headers: {
              "Authentication-Token": store.getters["authentication/getToken"],
            },
          }
        )
        .then((res) => {
          this.proposal = Object.assign(this.proposal, res.data.proposal);
          this.extraProposalInfos = res.data?.extra_proposal_infos;
        });
    }
  },
  methods: {
    paymentType() {
      if (this.proposal.payment_method == "BOLETO") {
        return { first: "Boleto", others: "Boleto" };
      }
      if (this.proposal.payment_method == "PIX") {
        return { first: "Pix", others: "Pix" };
      }
      if (this.proposal.payment_method == "FIRST_BOLETO_OTHERS_PIX") {
        return { first: "Pix", others: "Débito" };
      }
      if (this.proposal.payment_method == "FIRST_PIX_OTHERS_DEBIT") {
        return { first: "Pix", others: "Débito" };
      }
      if (this.proposal.payment_method == "FIRST_PIX_OTHERS_BOLETO") {
        return { first: "Pix", others: "Boleto" };
      }
      if (this.proposal.payment_method == "FIRST_BOLETO_OTHERS_DEBIT") {
        return { first: "Boleto", others: "Débito" };
      }
      if (this.proposal.payment_method == "FIRST_BOLETO_OTHERS_CREDIT") {
        return { first: "Boleto", others: "Cartão de crédito" };
      }
      if (this.proposal.payment_method == "PARCELE_JA") {
        return { first: "Cartão de crédito", others: "Cartão de crédito" };
      }
      return {};
    },
    showPaymentsMethods() {
      let configPrefix = util.getPrefixByProposal(this.proposal.id);
      let showPaymentsMethods =
        util.getConfig()[configPrefix + "show_product_on_confirm_details"];
      if (showPaymentsMethods) return true;
      return false;
    },
  },
  computed: {
    ...mapGetters("negotiation", {
      negotiation: "getNegotiation",
    }),
    showProductAndContract() {
      let configPrefix = util.getPrefixByProposal(this.proposal.id);
      let showProduct =
        util.getConfig()[configPrefix + "show_product_on_confirm_details"];
      if (showProduct) return true;
      return false;
    },
    totalAmountToPay() {
      if (this.proposal?.total_payment) return this.proposal.total_payment;
      return Proposal.calculateTotalAmountToPay(this.proposal);
    },
    isInstallmentsCountVisible() {
      if (!this.proposal.installments_count_1) {
        return false;
      }

      if (Proposal.isIrregularFlow(this.proposal)) {
        return false;
      }

      return true;
    },
    isDetailsVisible() {
      if (!this.proposal) {
        return false;
      }

      if (this.proposal.creditor_id == CreditorId.GERU) {
        return false;
      }

      if (this.extraProposalInfos) return true;

      return (
        this.proposal.installments_count_1 || this.proposal.discount_rate != 0
      );
    },
    isInstallmentVisible() {
      if (!this.proposal.installment_1) {
        return false;
      }

      if (Proposal.isIrregularFlow(this.proposal)) {
        return false;
      }

      return true;
    },
    extraInfosArray() {
      return this.extraProposalInfos.length
        ? this.extraProposalInfos
        : this.propsExtraProposalInfos;
    },
  },
};
</script>

<style scoped lang="scss">
.qj-list {
  list-style-type: none;
  background: #f6f6f6;
  border-radius: 7px;
  padding: 0 !important;
}

.qj-iten-list-flex,
.qj-iten-list {
  padding: 10px;
  line-height: initial;
}

.qj-iten-list-flex {
  display: flex;
}

.qj-iten-list-flex b,
.qj-iten-list b {
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.qj-list-green {
  list-style-type: none;
  background: #cafce9;
  border-radius: 7px;
  padding: 10px 9px !important;
}

.qj-list-green li {
  display: flex;
  margin-top: 14px;
}

.qj-list-green li b {
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #000000;
}

.qj-icon-list {
  font-size: 20px;
  vertical-align: middle;
  line-height: 12px;
  margin-top: -3px;
  margin-left: 3px;
}

.qj-proposal-alias {
  font-size: 14px;
  padding: 12px 12px 0 !important;
  text-align: center;
}

.qj-proposal-down-payment {
  font-weight: 600;
}

.qj-proposal-irregular-flow {
  line-height: 15px;

  .installment {
    font-weight: 800;
  }
}

.qj-proposal-conditions {
  margin-bottom: 8px;
  padding-top: 0px;
  text-align: center;
}

.qj-installment-details {
  .observation {
    font-size: 13px;
    line-height: 16px;
    margin: 0;
    max-width: 300px;
    padding: 4px 8px 12px;
  }

  .qj-iten-list-flex {
    font-size: 13px;
    padding: 6px 12px;

    p {
      font-size: 13px;
    }
  }
}

.form-email {
  padding: 0 16px;

  .qj-input {
    margin-top: 4px;
  }
}

.v-expansion-panel::before {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
    0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
}

.header-img {
  max-width: 135px;
  max-height: 45px;

  @media (min-width: 426px) {
    max-width: 190px;
    max-height: 50px;
  }
}

.color-blue {
  color: #2962ff !important;
}
</style>
