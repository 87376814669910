<template>
  <div class="optout-view">
    <img src="@/assets/img/logo/logo_quiteja.svg" class="qj-img" alt="QuiteJá" />
    <h1 class="title">Oi, {{ customerName }}, tudo bem?</h1>
    <p class="text">Entendemos que você não deseja mais receber nossas novidades e
      ofertas exclusivas de renegociação pelo <b>WhatsApp</b>.
    </p>
    <p class="text">
      <b>Confirme sua escolha</b> e nós respeitaremos sua decisão.
      Lembre-se que você ainda pode nos contatar por outros meios!
    </p>

    <v-btn class="agreed-button" @click="showConfirmDialog = true" :disabled="loading">
      Solicitar opt-out
    </v-btn>

    <router-link :to="{ path: 'confirmar', query: { hash }, replace: true }" class="qj-link qj-color-secondary"
      id="return__to__other__negotiations">
      Voltar
    </router-link>

    <span class="qj-msg-safe">
      <v-icon>mdi-lock-outline</v-icon>
      Você está em um ambiente seguro.
    </span>

    <ConfirmOptOutModal 
      :dialog="showConfirmDialog"
      @close="showConfirmDialog = false"
      @confirm="agreedOptout"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ContactService from '@/services/contact';
import ConfirmOptOutModal from '@/components/DialogConfirmOptOut.vue';

export default {
  components: {
    ConfirmOptOutModal,
  },
  props: {
    hash: {
      type: String,
      default: null
    },
    source_channel: {
      type: String,
      default: null
    },
    utm_source: {
      type: String,
      default: null
    },
    utm_medium: {
      type: String,
      default: null
    },
    utm_campaign: {
      type: String,
      default: null
    },
    utm_content: {
      type: String,
      default: null
    },
    utm_term: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      customerName: "",
      loading: false,
      showConfirmDialog: false,
    };
  },
  computed: {
    ...mapGetters("authentication", ["getToken"]),
  },
  beforeCreate() {
    this.$emit("loading", true);
  },
  async created() {
    let payload = {
      data: {
        auth_type: "HASH",
        hash_id: this.hash,
        source_channel: this.source_channel,
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        utm_content: this.utm_content,
        utm_term: this.utm_term,
      },
      token: "",
    };

    try {
      await this.login(payload);
      let { name } = this.$jwt.decode(this.getToken);
      this.customerName = name;
    } catch (error) {
      console.error('Não foi possível fazer o login.', error);
      this.$router.push({ name: "PageNotFound" })
    } finally {
      this.$emit("loading", false);
    }
  },
  methods: {
    ...mapActions("authentication", ["login"]),
    async agreedOptout() {
      this.loading = true;
      this.showConfirmDialog = false;
      let payload = {
        'campaign': this.utm_campaign,
        'channel': this.source_channel,
        'hashid': this.hash,
        'medium': this.utm_medium,
        'source': this.utm_source
      }

      try {
        await ContactService.optoutWhatsapp(payload);
        this.$router.push({ path: 'confirmar', query: { hash: this.hash }, replace: true });
      } catch (error) {
        console.error('Não foi possível processar o opt-out de contato.', error);
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.optout-view {
  .title,
  .text,
  .text b {
    color: #404040;
  }

  .title {
    font-family: Montserrat !important;
    font-size: 20px !important;
    font-weight: 800;
    line-height: normal;
    margin: 20px 0px 16px;
  }

  .text {
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;

    b {
      font-weight: 700;
    }
  }

  .agreed-button {
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
    width: fit-content;
    margin: 0 auto 28px;
    font-size: 16px;
    font-weight: 700;
    background: #FF6B6B;
    color: white;
  }

  .qj-link {
    vertical-align: sub;
    text-align: center;
  }

  .qj-msg-safe {
    align-self: center;
    text-align: center;
    margin-top: 100px;

    @media (min-height: 630px) {
      position: fixed;
      bottom: 32px;
    }
  }

  @media (min-width: 600px) {
    .qj-img {
      align-self: flex-start;
    }

    .title {
      font-size: 38px !important;
      margin: 40px 0px 20px;
    }

    .agreed-button {
      margin-top: 8px;
    }

    .qj-link {
      margin-bottom: 16px;
    }

    .qj-msg-safe {
      margin-top: 0px;
    }

    @media (min-height: 630px) {
      .qj-link {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
