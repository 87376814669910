<template>
  <div>
    <!-- Body -->
    <v-row v-if="deal && !dialogReloadProposals" class="align-content-start">
      <!-- Description logo creditor -->
      <v-col v-if="deal.creditor_profile" cols="12" class="pt-0">
        <p class="mb-0 qj-p">
          Localizamos {{ operationsDescription }}
          {{ deal.creditor.profile.long_name }}
          <b style="white-space: pre"
            >{{ activeOperations.length }}
            {{ hasManyOperations ? "contratos" : "contrato" }}</b
          >
          em atraso
        </p>
      </v-col>

      <v-col cols="12" class="pt-0">
        <operationsBox
          :activeOperations="activeOperations"
          :showDetails="showDetails"
          :isCreditorSecuritizier="isCreditorSecuritizier"
          @openDialog="openDialogSecuritizer"
        />
      </v-col>

      <v-col v-if="doShowContractLink()" cols="12" class="text-center pt-0">
        <a
          class="card-a"
          href="javascript:void(0);"
          @click="openContractDeatails()"
          >Detalhes do contrato</a
        >
      </v-col>

      <v-col
        cols="12"
        class="pt-0 qj-alert-info"
        v-if="deal.type == 'FOMENTATION'"
      >
        <div class="text-center">
          <v-btn
            v-if="!deal.creditor_id == 17"
            class="rounded-lg qj-btn qj-bg-primary"
            @click="openFomentationLink()"
            :disabled="loadingFomentationLink"
            :loading="loadingFomentationLink"
          >
            Acessar {{ deal.creditor_profile.long_name }}
          </v-btn>
        </div>

        <qj-alert-info
          :creditorId="deal.creditor_id"
          name="deal-proposals-after-operations"
          :deal="deal"
          @openLink="openFomentationLink()"
        />
      </v-col>
      <!-- Sem propostas -->
      <v-col cols="12" class="pt-0" v-else-if="!hasProposals(proposals)">
        <div class="qj-msg">
          <p class="qj-p mb-0 text-center">
            No momento você não possui nenhuma proposta para esta dívida.
          </p>
        </div>
      </v-col>
      <!-- Description options -->
      <v-col cols="12" class="py-0" v-else-if="hasProposals(proposals)">
        <p class="mb-0 qj-p">
          Destacamos
          <b>
            {{ hasManyProposals(proposals) }}
          </b>
          para você<span v-if="!isSimulationVisible">.</span>
          <span v-else
            >, escolha uma das opções ou
            <a
              href="javascript:void(0)"
              @click="openDialogSimulation()"
              class="simulation-link"
            >
              simule</a
            >
            no final da página
          </span>
        </p>
      </v-col>
      <!-- Payment options -->
      <v-col cols="12" class="pt-0">
        <div v-for="proposal in proposals" :key="proposal.id">
          <div v-if="campaigns[proposal.incentive] && hasProposals(proposals)">
            <v-col
              v-if="proposal == proposalsWithCampaign?.[0]"
              class="d-flex align-center justify-center px-4"
              :style="campaigns[proposal.incentive].banner?.style"
              :class="campaigns[proposal.incentive].banner?.class"
            >
              <div class="campaign-box">
                <div
                  class="campaign-box__header d-flex justify-center align-center flex-column"
                >
                  <div
                    class="campaign-box__header__text mt-3"
                    :style="campaigns[proposal.incentive].banner?.textStyle"
                  >
                    {{ campaigns[proposal.incentive].banner?.text }}
                  </div>
                  <div class="campaign-box__header__seal align-self-end">
                    <v-img
                      max-height="84"
                      max-width="84 "
                      :src="campaigns[proposal.incentive].seal"
                    ></v-img>
                  </div>
                </div>
                <div
                  v-for="proposal in proposalsInCampaign(proposal.incentive)"
                  :key="proposal.id"
                  class="qj-card-outline my-6"
                  :class="returnProposalBoxClass(proposal)"
                  :style="generateProposalBoxStyle(proposal)"
                  style="background-color: white"
                  v-bind:id="'proposal-' + proposal.id"
                >
                  <ProposalCard
                    :style="proposalCardStyle"
                    :deal="deal"
                    :proposal="proposal"
                    :proposals="proposals"
                    :proposalOfferType="proposalOfferType"
                    :showAlias="true"
                    :doShowNextButton="doShowNextButton"
                    :dontShowPaymentMethodTags="dontShowPaymentMethodTags"
                    @next="next(proposal)"
                  />
                </div>
              </div>
            </v-col>
          </div>
          <div
            v-else
            class="qj-card-outline my-4"
            v-bind:id="'proposal-' + proposal.id"
            :style="generateProposalBoxStyle(proposal)"
            :class="
              ({
                promo: proposal.expires_in && proposal.creditor_id == 10,
                'renegocia-border': checkMaxInstallmentOnCmapaign(proposal),
              },
              returnProposalBoxClass(proposal))
            "
          >
            <ProposalCard
              :deal="deal"
              :proposal="proposal"
              :proposals="proposals"
              :proposalOfferType="proposalOfferType"
              :doShowNextButton="doShowNextButton"
              :dontShowPaymentMethodTags="dontShowPaymentMethodTags"
              :showAlias="true"
              @next="next(proposal)"
              @nextToParceleJa="next($event.proposal, null, $event.type)"
            />
          </div>
        </div>
      </v-col>

      <!-- Outras opções -->
      <v-col
        cols="12"
        class="pt-0 pb-0 qj-bt-show-all-proposals"
        v-if="isShowAllProposalsVisible"
      >
        <v-btn
          class="rounded-lg qj-btn qj-bg-secondary-light qj-p-btn"
          @click="showAllProposals()"
        >
          Ver outras opções
        </v-btn>
      </v-col>

      <!-- Btn others options -->
      <v-col
        cols="12"
        class="pt-0 pb-0 qj-bt-simulate"
        v-if="isSimulationVisible"
      >
        <v-btn
          class="rounded-lg qj-btn qj-bg-secondary-light qj-p-btn"
          @click="openDialogSimulation()"
        >
          Simular outros valores
        </v-btn>

        <div class="description">Encontre o valor que cabe em seu bolso.</div>
      </v-col>
    </v-row>

    <v-row
      v-else
      style="height: 100%"
      class="mt-auto d-flex justify-center loading"
    >
      <v-progress-circular
        indeterminate
        class="qj-color-primary loading__circle"
        :size="50"
      ></v-progress-circular>
    </v-row>
    <!-- Footer -->
    <qj-footer :type="footerType" view="negociacao/divida" />
    <DialogSimulation
      :deal="deal"
      :dialog="dialogSimulation"
      :simulationParams="simulationParams"
      @close="closeDialogSimulation"
      @step="$emit('step', 2)"
    />
    <DialogSimulationV2
      v-if="simulationTypeV2() && simulationParams"
      :deal="deal"
      :dialog="dialogSimulationV2"
      @close="closeDialogSimulationV2"
      @step="$emit('step', 2)"
      :simulationType="simulationTypeV2()"
      :simulationParams="simulationParams"
      @next="next($event)"
    />
    <DialogSecuritizer
      :deal="deal"
      :dialog="dialogSecuritizer"
      @close="closeDialogScuritizer"
    />
    <DialogDetailsContract
      :deal="deal"
      :dialog="dialogDetailsContract"
      @close="dialogDetailsContract = false"
    />
    <ReloadProposalsDialog
      class="shrink"
      :dialog="dialogReloadProposals"
      @closeReloadProposalDialog="closeReloadProposalDialog"
    />
  </div>
</template>

<script>
import * as Sentry from "@sentry/browser";
import { GenerateProposalsError, LoadDealError } from "@/errors";

import Vue from "vue";
import moment from "moment";
import store from "@/store";
import { mapActions, mapMutations, mapGetters } from "vuex";
import Deal from "@/modules/deal";
import http from "@/plugins/axios";
import util from "@/assets/js/util";
import Contact from "@/modules/contact";
import serviceUtil from "@/services/util";
import serviceDeal from "@/services/deal";
import serviceProposal from "@/services/proposal";
import DialogSimulation from "../components/DialogSimulation";
import DialogSimulationV2 from "../components/DialogSimulationV2";
import DialogSecuritizer from "../components/DialogSecuritizer";
import DialogDetailsContract from "../components/DialogDetailsContract";
import { createTagManagerEvent } from "@/utils";
import ReloadProposalsDialog from "../components/DialogReloadProposal";
import ProposalCard from "../components/proposalCard.vue";
import OperationsBox from "../components/operationsBox.vue";
import campaigns from "@/assets/js/campaigns";

export default {
  components: {
    DialogSimulation,
    DialogSimulationV2,
    DialogSecuritizer,
    DialogDetailsContract,
    ReloadProposalsDialog,
    ProposalCard,
    OperationsBox,
  },
  data: () => ({
    // Indica se está carregando o link para o fomento
    campaigns: campaigns,
    proposalsWithCampaign: null,
    showDetails: false,
    dontShowPaymentMethodTags: false,
    adapterUrl: null,
    loadingFomentationLink: false,
    timeFomentation: 12,
    dialogSimulationV2: false,
    redirectInterval: {},
    // Indica se é possível fazer simulação para a negociação
    allowsSimulation: false,
    /** Lista de todas as propostas, sendo destacadas ou não. */
    allProposals: [],
    deal: null,
    dialog: false,
    dialogSimulation: false,
    dialogSecuritizer: false,
    dialogReloadProposals: false,
    dialogMyData: false,
    dialogDetailsContract: false,
    proposalCardStyle: null,
    /** Tipo do rodapé. */
    footerType: null,
    simulationParams: null,
    /**
     * Lista das propostas que serão exibidas.
     * Se for informado um Tipo da Oferta então serão exibidas apenas as propostas destacadas para o
     * Tipo da Oferta informado.
     * Ex.: À Vista, Prazo Máximo, etc.
     */
    proposals: [],
    /** Tipo da Oferta das propostas destacadas. */
    proposalOfferType: null,
    doShowNextButton: true,
  }),
  computed: {
    ...mapGetters("negotiation", {
      selectedDeal: "getSelectedDeal",
    }),
    ...mapGetters("pluralCheck", {
      hasManyOperations: "hasManyOperations",
    }),

    /** Retorna true se o botão para mostrar todas as propostas deve ser exibido. */
    isShowAllProposalsVisible() {
      return this.allProposals?.length != this.proposals?.length;
    },
    isOnlyInCash() {
      const hasInstallments = this.deal.proposals.some((proposal) => {
        return proposal.payment_type == "INSTALLMENT";
      });
      return hasInstallments;
    },
    /** Retorna true se o botão de simulação deve ser exibido. */
    isSimulationVisible() {
      if (
        this.isShowAllProposalsVisible &&
        !this.deal._showAllProposals &&
        this.isOnlyInCash
      ) {
        return false;
      }

      if (this.allowsSimulation) {
        return true;
      }

      return false;
    },
    activeOperations() {
      return this.deal.operations.filter((i) => i.active);
    },
    operationsDescription() {
      const showUnderManagement =
        util.getConfig()[
          util.getPrefix(this.deal.id) + "show_under_management"
        ];
      const creditorGender =
        this.deal.creditor_profile.gender == "MALE" ? "o" : "a";

      if (showUnderManagement) return `sob a gestão d${creditorGender}`;
      return ` n${creditorGender}`;
    },
    isCreditorSecuritizier() {
      return (
        util.getConfig()[util.getPrefix(this.deal.id) + "creditor_type"] ===
          "SECURITIZATION" || false
      );
    },
  },
  async beforeCreate() {
    this.$emit("loading", true);
  },
  async created() {
    // Só mostra os passos se existirem propostas
    this.$route.meta.showStep = false;

    // Status válidos para negociação
    const validStatus = ["CREATED", "STARTED", "WAITING"];

    this.proposalOfferType = this.$route.query.oferta;
    if (!this.$route.query.id) {
      this.$emit("loading", false);
      this.$router.push({ name: "Home" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
      return;
    }

    this.$emit("step", 1);

    let deals = store.getters["negotiation/getDeals"] || [];
    let dealId = util.base64Decode(this.$route.query.id);
    let deal = deals.find((i) => i.id == dealId);

    if (!deal) {
      this.$emit("loading", false);
      this.$router.push({ name: "Home" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
      return;
    }
    this.setSelectedDeal(deal);
    this.showDetails = util.getConfigValue(
      deal.creditor.config_prefix + "show_details_on_deal"
    );
    this.dontShowPaymentMethodTags = util.getConfigValue(
      deal.creditor.config_prefix + "dont_show_payment_method_tag"
    );
    if (validStatus.indexOf(deal.status) == -1) {
      this.$emit("loading", false);
      this.$router.push({ name: "Home" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
      return;
    }

    if (!Deal.isNegotiable(deal)) {
      this.$emit("loading", false);
      this.$router.push({ name: "Home" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
      return;
    }

    console.debug("Negociação:", deal.id);

    Sentry.setContext("Deal", {
      aborted_cause: deal.aborted_cause,
      creditor: deal.creditor.name,
      id: deal.id,
      status: deal.status,
      waiting_cause: deal.waiting_cause,
    });

    Sentry.setTag("deal.creditor", deal.creditor.name);
    Sentry.setTag("deal.status", deal.status);

    console.debug("Buscando as políticas da negociação");

    // Políticas da negociação
    let policies = [];

    try {
      const response = await serviceDeal.policy(deal.id);
      policies = response.data.policies || [];
    } catch (error) {
      console.error(
        "Não foi possível buscar as políticas da negociação.",
        error
      );
    }

    console.debug("Políticas encontradas:", policies.length);

    // Se a negociação for fomento
    if (deal.type == "FOMENTATION") {
      deal.creditor_profile = deal.creditor.profile;
      this.deal = deal;
      this.$emit("loading", false);
      this.$route.meta.showStep = 1;
      this.$emit("totalStep", 1);
      return;
    }

    let proposals = [];

    // É necessário chamar a API do deal somente uma vez
    if (util.isEmpty(deal._proposals_generated)) {
      console.debug("Buscando a negociação.");

      await this.ActionsDeal({
        deal_id: deal.id,
      })
        .then((res) => {
          deal.creditor_profile = res.creditor_profile || deal.creditor.profile;
          deal.customer_params = res.customer_params;
          deal.operations = res.operations;
          deal.proposals = res.proposals;
          if (window.hj) window.hj("event", deal.creditor.name);
          if (deal.creditor_profile.long_name) {
            if (window.hj) window.hj("event", deal.creditor_profile.long_name);

            this.createTagManagerEvent({
              event: "proposalsListed",
              creditor: deal.creditor_profile.long_name,
              creditorProfile: deal.creditor_profile.short_name,
            });
          }
        })
        .catch((error) => {
          {
            console.error(
              "Não foi possível buscar a negociação.",
              JSON.stringify(error)
            );

            let eventId = Sentry.captureException(
              new LoadDealError({
                axiosError: error,
              })
            );

            this.$router
              .push({ name: "Error", query: { id: eventId } })
              .catch((failure) => {
                this.skipIfNavigationFailureIsGeneric(failure);
              });

            return;
          }
        });
    }
    if (!deal.active_proposal_id && util.isEmpty(deal._proposals_generated)) {
      // Gera as propostas para a negociação
      let payload = { deal_id: deal.id };
      let adapterUrl = util.getConfigValue(
        deal.creditor.config_prefix + "proposal_generation_url"
      );
      this.adapterUrl = adapterUrl;

      // Mede o tempo de geração das propostas
      let generateStart = Math.round(performance.now());

      if (adapterUrl) {
        deal.proposals = await this.generateProposalOnAdapter(
          adapterUrl,
          payload
        );
      } else {
        deal.proposals = await this.generateProposalOnCore(payload);
      }
      if (deal.proposals?.length < 1) {
        this.$router
          .replace({ name: "500", params: { error: undefined, stay: true } })
          .catch((failure) => {
            console.error(failure);
          });
      }
      console.debug("Propostas retornadas:", proposals.length);

      // Envia o tempo de geração das propostas
      let value = Math.round(performance.now()) - generateStart;

      console.debug("Tempo de geração das propostas:", value / 1000);
      if (window.hj)
        window.hj("event", `Tempo para gerar as propostas: ${value / 1000}`);

      util.googleAnalyticsTiming(
        "Dívida",
        "geração das propostas",
        value,
        deal.creditor.name
      );
      deal._proposals_generated = deal.proposals ? true : null;
    }
    if (this.$route.meta.skipToHighlightOne) {
      const highLightOneProposal = deal?.proposals?.find((proposal) => {
        return proposal.highlight == 1;
      });
      await this.next(highLightOneProposal, true);
    }
    if (util.isEmpty(deal.proposals)) {
      this.$emit("totalStep", 1);
    } else {
      if (deal.proposals?.[0]?.debit_suggestion_id) this.$emit("totalStep", 5);
      else this.$emit("totalStep", 4);
    }

    this.deal = deal;

    this.setSelectedDeal(this.deal);

    this.footerType = deals.length > 1 ? "other" : "help";

    setTimeout(() => {
      this.$emit("loading", false);
      this.$vuetify.goTo(0, { duration: 0 });
    }, 300);

    let abandonedProposal = Deal.getAbandonedProposal(this.deal.proposals);

    // Se existir proposta do tipo "carrinho abandonado" então redireciona
    // para a tela de confirmação de proposta
    if (abandonedProposal && !abandonedProposal._abandonedProposalViewed) {
      console.info("Proposta do carrinho abandonado:", abandonedProposal.id);

      let downPaymentDueDate = moment(new Date()).add(1, "days");

      // Escolhe a primeira data de vencimento disponível
      if (
        abandonedProposal._down_payment_dates &&
        abandonedProposal._down_payment_dates.length > 0
      ) {
        downPaymentDueDate = moment(abandonedProposal._down_payment_dates?.[0]);
      }

      // Escolhe o e-mail
      let customer = store.getters["negotiation/getMe"];
      let email = null;
      let emails = Contact.getContacts(customer.contacts, "EMAIL");

      if (emails && emails.length > 0) {
        email = emails?.[0]?.value;
      }

      // Cria um campo na prooposta para indicar que o aviso de carrinho
      // abandonado já foi exibido para o cliente
      Vue.set(abandonedProposal, "_abandonedProposalViewed", true);

      // Verifica se existem condições para a proposta
      this.getProposalConditions(abandonedProposal.id);

      this.$router
        .push({
          name: "NegotiationConfirmDeal",
          params: {
            date: downPaymentDueDate.format("YYYY-MM-DD"),
            email: email,
          },
          query: {
            id: window.btoa(abandonedProposal.id),
            carrinho: true,
          },
        })
        .catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure);
        });

      this.$emit("loading", false);
    }

    console.debug(
      "Permite simulação:",
      this.allowsSimulation && this.simulationParams
    );

    if (this.allowsSimulation && this.proposalOfferType == "simulation") {
      setTimeout(() => {
        console.debug("Abrindo o simulador.");
        this.dialogSimulation = true;
      }, 500);
    }

    this.allProposals = Deal.getValidProposals(this.deal);

    console.debug("Propostas válidas:", this.allProposals.length);

    // Busca apenas as propostas que devem ser exibidas em destaque
    this.proposals = Deal.getHighlightedProposals(
      this.allProposals,
      this.proposalOfferType
    );
    if (this.deal._showAllProposals) {
      // Se todas as propostas já foram exibidas então ignora o Tipo da Oferta
      this.proposals = this.allProposals;
    } else if (this.proposals.length == 0) {
      this.proposals = this.allProposals.sort((proposalA, proposalB) => {
        if (Number(proposalA.highlight) > Number(proposalB.highlight)) return 1;
        if (Number(proposalA.highlight) < Number(proposalB.highlight))
          return -1;
        return 0;
      });
    } else {
      console.debug("Propostas destacadas:", this.proposals.length);
    }

    this.checkForCampaigns();
    await this.setOperationsLength(this.deal.operations);
    // Só mostra os passos se existirem propostas
    this.$route.meta.showStep = this.proposals.length > 0;

    // Verifica se é possível fazer simulação para a negociação
    this.simulationParams = await this.getSimulationParams(this.deal.id);
    this.allowsSimulation = Deal.allowsSimulation(deal, policies);
    if (
      !this.simulationParams ||
      (Array.isArray(this.simulationParams) &&
        this.simulationParams?.length === 0)
    ) {
      this.allowsSimulation = false;
    }

    console.debug("Permite simulação:", this.allowsSimulation);
  },
  methods: {
    ...mapMutations("negotiation", {
      setSelectedDeal: "setSelectedDeal",
      setLoadingFomentationLink: "setLoadingFomentationLink",
      setNewProposal: "setNewProposal",
      setNegotiation: "setNegotiation",
    }),
    ...mapActions("negotiation", ["ActionsDeal", "fetchContractDetails"]),
    ...mapActions("proposal", {
      getProposalConditions: "getConditions",
    }),
    ...mapActions("pluralCheck", ["setOperationsLength"]),
    ...mapActions("simulator", ["getSimulationParams", "simulate"]),
    checkForCampaigns() {
      let proposalsWithCampaign = [];
      let proposalsWithNoCampaign = [];
      this.proposals.forEach((proposal) => {
        if (this.campaigns[proposal.incentive]) {
          proposalsWithCampaign.push(proposal);
        } else {
          proposalsWithNoCampaign.push(proposal);
        }
      });
      this.proposalsWithCampaign = proposalsWithCampaign;
    },
    proposalsInCampaign(campaign) {
      const proposals = this.proposals.filter((proposal) => {
        if (proposal.incentive == campaign) {
          return true;
        }
      });
      return proposals;
    },
    checkMaxInstallmentOnCmapaign(proposal = null) {
      if (!proposal) return false;
      let maxInstallmentWithCampaign = this.deal.proposals.filter(
        (proposal) => {
          return proposal.campaign == "ITAU_CAMPANHA_REORGANIZA";
        }
      );
      return maxInstallmentWithCampaign.some((p) => {
        return p.payment_type == "INSTALLMENT" && p == proposal;
      });
    },
    isCampaign({ name = null, proposal = false, deal = false }) {
      if (proposal) return proposal?.campaign == name;
      if (deal) return deal?.tags?.includes(name);
      return false;
    },
    redirectToNegotiationConfirmedProposal() {
      const uniqProposal = this.proposals?.[0];

      console.info("Proposta única:", uniqProposal.id);

      let downPaymentDueDate = moment(new Date()).add(1, "days");

      // Escolhe a primeira data de vencimento disponível
      if (
        uniqProposal._down_payment_dates &&
        uniqProposal._down_payment_dates.length > 0
      ) {
        downPaymentDueDate = moment(uniqProposal._down_payment_dates?.[0]);
      }

      this.$router
        .push({
          name: "NegotiationConfirmDeal",
          params: {
            date: downPaymentDueDate.format("YYYY-MM-DD"),
          },
          query: {
            id: window.btoa(uniqProposal.id),
            carrinho: true,
          },
        })
        .catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure);
        });

      this.$emit("loading", false);
    },
    simulationTypeV2() {
      const simulationTypeCofig = util.getConfigValue(
        this.deal?.creditor?.config_prefix + "simulation.type"
      );
      const simulationTypeCustomer =
        this.deal?.customer_params?.simulation_type;
      return simulationTypeCustomer || simulationTypeCofig;
    },
    openDialogSimulation() {
      createTagManagerEvent({
        event: "dealClickedOpenSimulation",
      });
      if (this.simulationTypeV2()) {
        this.dialogSimulationV2 = true;
      } else {
        this.dialogSimulation = true;
      }
    },
    openDialogSecuritizer() {
      this.dialogSecuritizer = true;
    },

    /** Indica se o valor das operações (saldo devedor) deve ser exibido. */

    /** Exibe todas as propostas. */
    showAllProposals() {
      // Primeira proposta que será adicionada na lista
      // Será usada para fazer o scroll na tela
      let firstProposal = null;

      // Adiciona na lista o restante das propostas que não estão sendo exibidas
      this.allProposals.forEach((proposal) => {
        let isVisible = this.proposals.find((p) => p.id == proposal.id);

        if (!isVisible) {
          this.proposals.push(proposal);

          if (!firstProposal) {
            firstProposal = proposal;
          }
        }
        this.$forceUpdate();
      });

      this.deal._showAllProposals = true;

      // Faz o scroll até a primeira proposta adicionada na lista
      this.$nextTick(function () {
        if (firstProposal) {
          this.$vuetify.goTo("#proposal-" + firstProposal.id, {
            duration: 1000,
          });
        }
      });
    },
    /** Retorna true se o selo da "Melhor Opção" deve aparecer na proposta. */

    openReloadProposalsDIalog() {
      this.dialogReloadProposals = true;
      this.reloadProposal();

      createTagManagerEvent({
        event: "dealReloadProposals",
      });
    },
    closeDialogSimulation() {
      this.dialogSimulation = false;
    },
    closeDialogSimulationV2() {
      this.dialogSimulationV2 = false;
    },
    closeDialogScuritizer() {
      this.dialogSecuritizer = false;
    },
    closeReloadProposalDialog() {
      this.dialogReloadProposals = false;
    },
    async generateProposalOnCore(payload) {
      console.debug("Gerando as propostas no core");
      const transaction = Sentry.startTransaction({
        name: "generateProposals",
      });
      Sentry.getCurrentHub().configureScope((scope) =>
        scope.setSpan(transaction)
      );
      let proposals;
      try {
        const response = await serviceProposal.generate(payload);
        proposals = response.data;
        var span = transaction.startChild({
          data: {
            proposals,
          },
          op: "task",
          description: `Gerando propostas no core`,
        });
        Sentry.setTag("creditor_id", this.selectedDeal.creditor_id);
      } catch (error) {
        console.error("Não foi possível gerar as propostas no core.", error);
        Sentry.captureException(
          new GenerateProposalsError({
            axiosError: error,
            message: "Não foi possível gerar as propostas",
          })
        );
      } finally {
        if (proposals) {
          span.finish();
          transaction.finish();
        }
      }

      return proposals;
    },
    async generateProposalOnAdapter(adapterUrl, payload) {
      console.debug("Gerando as propostas no adapter");
      const transaction = Sentry.startTransaction({
        name: "generateProposalsOnAdapter",
      });
      Sentry.getCurrentHub().configureScope((scope) =>
        scope.setSpan(transaction)
      );
      let proposals;
      try {
        const response = await http.apiAdapter.post(adapterUrl, payload);
        proposals = response.data;
        var span = transaction.startChild({
          data: {
            proposals,
          },
          op: "task",
          description: `Gerando propostas no core`,
        });
      } catch (error) {
        console.error("Não foi possível gerar as propostas no adapter.", error);
        Sentry.captureException(
          new GenerateProposalsError({
            axiosError: error,
            message: "Não foi possível gerar as propostas",
          })
        );
      } finally {
        if (proposals) {
          span.finish();
          transaction.finish();
        }
      }
      return proposals;
    },
    async reloadProposal() {
      let deals = store.getters["negotiation/getDeals"] || [];
      let dealId = util.base64Decode(this.$route.query.id);
      let deal = deals.find((i) => i.id == dealId);
      let adapterUrl = util.getConfigValue(
        deal.creditor.config_prefix + "proposal_generation_url"
      );
      this.adapterUrl = adapterUrl;
      this.proposals = null;
      let response;
      if (this.adapterUrl) {
        response = await this.generateProposalOnAdapter(this.adapterUrl, {
          deal_id: this.deal.id,
        });
      } else {
        response = await this.generateProposalOnCore({ deal_id: this.deal.id });
      }

      this.proposals = response;
      this.deal.proposals = response;
    },
    async selectProposal(proposal_id) {
      let status;
      try {
        await serviceProposal.get(proposal_id).then((res) => {
          status = res.data.status;
        });
      } catch (error) {
        console.debug("Não foi possível consulta a proposta.", error);
        return true;
      }
      if (status != "CREATED" && status != "SELECTED") return false;
      return true;
    },
    async next(proposal, skip, type) {
      const isValid = await this.selectProposal(proposal.id);
      if (!isValid) {
        this.openReloadProposalsDIalog();
        this.proposals = null;
        return;
      } else {
        serviceProposal
          .update({
            id: proposal.id,
            status: "SELECTED",
          })
          .catch((error) => {
            console.error(
              "Não foi possível atualizar a proposta",
              proposal.id,
              error
            );
          });

        this.setNegotiation({
          date: null,
          installmentDueDate: null,
        });

        // Verifica se existem condições para a proposta
        this.getProposalConditions(proposal.id);

        if (proposal.id) {
          createTagManagerEvent({
            event: "proposalSelected",
            operationsValue: proposal.operations_value,
            paymentMethod: proposal.payment_method,
            paymentType: proposal.payment_type,
            presentValue: proposal.operations_value - proposal.discount,
            proposalType: proposal.type,
          });
        }

        this.$emit("step", 2);
        if (proposal.debit_suggestion_id) {
          this.$router
            .push({
              name: "NegotiationPaymentMethod",
              query: { id: window.btoa(proposal.id) },
            })
            .catch((failure) => {
              this.skipIfNavigationFailureIsGeneric(failure);
            });
        } else if (type === "PARCELE_JA") {
          this.$router
            .push({
              name: "NegotiationParceleJa",
              query: { id: window.btoa(proposal.id) },
            })
            .catch((failure) => {
              this.skipIfNavigationFailureIsGeneric(failure);
            });
        } else {
          this.$router[`${skip ? "replace" : "push"}`]({
            name: "NegotiationPaymentInfo",
            query: { id: window.btoa(proposal.id) },
          }).catch((failure) => {
            this.skipIfNavigationFailureIsGeneric(failure);
          });
        }
      }
    },
    /** Redireciona para o link do fomento. */
    async openFomentationLink() {
      createTagManagerEvent({
        event: "accessedMercadoLivre",
      });
      let url =
        util.getConfig()[util.getPrefix(this.deal.id) + "fomentation_link"];
      if (util.isEmpty(url)) return;

      this.loadingFomentationLink = true;
      this.setLoadingFomentationLink(true);

      await serviceUtil
        .generic(
          url,
          "post",
          {
            deal_id: this.deal.id,
          },
          {
            headers: {
              "Authentication-Token": store.getters["authentication/getToken"],
            },
          }
        )
        .then((res) => {
          this.setLoadingFomentationLink(false);
          if (util.isEmpty(res.data.redirect_url)) {
            console.error("Link para o fomento não foi retornado.");

            this.loadingFomentationLink = false;
            this.setLoadingFomentationLink(false);

            this.$emit("snackbar", {
              snackbar: true,
              text: "Houve um erro. Tente novamente.",
            });
          } else {
            // let fakeElementToSimulateClick = document.createElement("a")
            // document.body.appendChild(fakeElementToSimulateClick)
            // fakeElementToSimulateClick.style = "display: none"
            // fakeElementToSimulateClick.href = res.data.redirect_url
            // fakeElementToSimulateClick.target = "blank"
            // fakeElementToSimulateClick.click()
            window.location.href = res.data.redirect_url;
          }
        })
        .catch((error) => {
          console.error("Não foi possível carregar o login do fomento.", error);

          this.loadingFomentationLink = false;
          this.setLoadingFomentationLink(false);

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => {
          this.setLoadingFomentationLink(false);
        });
    },
    updateTimeout(index, expires_in_timer) {
      let proposal = this.proposals[index];

      if (!proposal) {
        return;
      }

      Vue.set(proposal, "expires_in_timer", expires_in_timer);
    },
    hasProposals(proposals) {
      if (Array.isArray(proposals) && proposals?.length) {
        return true;
      }
      return false;
    },
    hasManyProposals(proposals) {
      if (Array.isArray(proposals) && proposals?.length > 1) {
        return proposals.length + " ofertas disponíveis";
      }
      return "1 oferta disponível";
    },
    openProfile() {
      this.dialogMyData = true;
    },
    returnProposalBoxClass(proposal) {
      if (!proposal) return "";
      if (proposal?.incentive == "Máximo parcelamento") {
        return "incentive-color-purple";
      } else if (proposal?.incentive == "Parcelamento máximo") {
        return "incentive-color-purple";
      } else if (proposal?.incentive == "Oferta sem juros") {
        return "incentive-color-gray";
      } else if (
        proposal.payment_type == "IN_CASH" &&
        proposal?.discount_rate > 0.1 &&
        !this.proposalOfferType &&
        proposal.id == this.proposals?.[0]?.id &&
        !proposal.incentive
      ) {
        return "incentive-color-pink";
      } else {
        return null;
      }
    },
    openContractDeatails() {
      this.dialogDetailsContract = true;
    },
    doShowContractLink() {
      let configPrefix = util.getPrefix(this.deal.id);
      if (!configPrefix) return false;
      let contractUrl =
        util.getConfig()[configPrefix + "url_contract_info"] ?? 0;

      if (contractUrl) {
        this.fetchContractDetails({
          contract_url: contractUrl,
          deal_id: this.deal.id,
        })
          .then()
          .catch(() => {
            this.$emit("alert", true);

            this.$emit("snackbar", {
              snackbar: true,
              text: "Houve um erro. Tente novamente.",
            });
          });
      }
      return contractUrl != 0;
    },
    generateProposalBoxStyle(proposal) {
      if (this.returnProposalBoxClass(proposal) || !proposal?.incentive)
        return null;
      return {
        borderColor: util.stringToColor(proposal.incentive) ?? "#853500",
      };
    },
  },
  filters: {
    countDown: function (time) {
      function zero(x) {
        if (x < 10) {
          x = "0" + x;
        }
        return x;
      }
      const countdown = new Date(time);
      const minutos = zero(countdown.getMinutes());
      const segundos = zero(countdown.getSeconds());
      return `${minutos}:${segundos}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");

.campaign-box {
  width: 100%;

  &__header {
    &__seal {
      width: 0px;
      height: 0px;

      .v-image {
        position: relative;
        right: 60px;
        top: -72px;
        width: 100px;
      }
    }
  }
}

.renegocia-info {
  background: #ffe7d8;
  height: 53px;
  width: 100%;
  border-radius: 7px;
  margin-left: -15px;

  &__text {
    display: block;
    max-width: 178px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: black;
  }
}

.renegocia-border {
  border-color: #ec7000;
}

.renegocia-chip {
  border-radius: 100px;
  background: #ffe7d8;
  height: 25px;
  width: 156px;

  span {
    color: #c36525;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
}

.promo {
  animation-name: scale;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  transform: rotate(0.00000000001deg);
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.promo {
  border-color: yellow;
  background-color: rgba(255, 255, 0, 0.1);
}

.expire-tag {
  .countdown-timer {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
  }

  text-align: initial;
  background-color: rgb(255, 250, 154);
  width: 204px;
}

.limited-tag {
  background-color: rgb(199, 255, 203);
}

.loading {
  position: absolute;
  left: calc(50%);
  top: 40%;
}

.border-b {
  border-bottom: 1px solid #e5e5e5;

  &:nth-last-child(2) {
    border: 0;
  }
}

.qj-proposal-down-payment {
  font-weight: 600;
}

.qj-proposal-irregular-flow {
  line-height: 15px;

  .installment {
    font-weight: 800;
  }
}

.qj-proposal-alias {
  font-size: 14px;
  font-weight: 700;
  padding: 12px 12px 0 !important;
  text-align: center;
}

.qj-proposal-incentive {
  font-size: 14px;
}

.qj-msg {
  padding: 10px;
  background: #f6f6f6;
  border-radius: 7px;
  margin-top: 10px;
}

.qj-creditor-logo {
  padding: 12px 12px 16px !important;

  img {
    margin-top: 3px;
  }
}

.qj-bt-show-all-proposals {
  margin-bottom: 40px !important;
  text-align: center;
}

.qj-bt-simulate {
  text-align: center;

  .qj-p-btn {
    font-weight: 800;
    color: #00606a;
  }

  .description {
    color: #000000;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 14px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.qj-proposal-badge {
  z-index: 100;
  height: 82px;
  margin-bottom: -26px;
  margin-right: -20px;
  margin-top: -60px;

  @media (max-width: 320px) {
    transform: scale(0.8);
    margin-right: -30px;
    margin-top: -52px;
  }

  @media (max-width: 307px) {
    display: none;
  }
}

.qj-redirect-span {
  font-size: 12px;

  .link {
    font-weight: 600;
    color: #28616b;
    cursor: pointer;
    text-decoration: underline;
  }
}

.big-discount {
  transition: scale(2.1);
}

.simulation-link {
  font-weight: 600;
  color: #277e5d;
}

.incentive-color-purple {
  border-color: #8741a9;
}

.incentive-color-gray {
  border-color: #9d9d9d;
}

.incentive-color-pink {
  border-color: #ff0099;
}
.card-a {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: #00606a;
}
</style>
