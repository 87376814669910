<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card class="text-center">
      <v-card-title class="justify-center">
        <h3 class="qj-h3">Confirmar Opt-out do WhatsApp</h3>
        <v-btn icon class="qj-btn-close-dialog" @click="$emit('close')" style="top: 5px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pl-3 pr-3 pb-3 mt-2">
        <img src="@/assets/img/svg/aviso.svg" alt="image_attention" width="70" height="70"/>

        <p class="qj-p mt-2 mx-5 mb-0">
          Tem certeza que deseja cancelar o recebimento de mensagens no WhatsApp? <b>Você não receberá mais notificações</b> sobre ofertas e negociações por este canal.
        </p>
        <v-btn
          color="#00e58d"
          class="rounded-lg qj-btn qj-p-btn mtop-15"
          @click="$emit('close')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="#BF4242"
          class="rounded-lg white--text qj-btn qj-p-btn mtop-15"
          @click="$emit('confirm')"
        >
          Confirmar Opt-out
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="scss" scoped>
.qj-btn-close-dialog {
  position: absolute;
  right: 5px;
  top: 5px;
}

.v-card__actions {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.v-card.v-sheet {
  border-radius: 10px !important;
}

.mtop-15 {
  margin-top: 15px;
}

.qj-btn {
  width: 200px;
}
</style>

