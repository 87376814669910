<template>
  <div class="qj-card-outline">
    <div>
      <v-row align="start" justify="space-between">
        <div style="max-height: 40px">
          <img
            class="qj-img"
            v-if="deal.creditor_profile"
            :src="deal.creditor_profile.logo_url"
            :alt="`Logo ${deal.creditor_profile.long_name}`"
          />
          <img
            class="qj-img"
            v-else-if="deal.creditor.profile"
            :src="deal.creditor.profile.logo_url"
            :alt="`Logo ${deal.creditor.profile.long_name}`"
          />
        </div>
        <div v-if="dontShowDiscount(deal) || !incashProposal(deal)">
          <div class="qj-tag-discount" style="padding-left: 8px">
            <span>Confira as <b>propostas</b></span>
          </div>
        </div>
        <div
          v-else-if="incashProposal(deal).discount_rate >= 0.01"
          class="pl-0"
        >
          <div class="qj-tag-discount">
            <img
              class="qj-img"
              src="@/assets/img/icons/sale.svg"
              alt="sale icon"
            />
            <span>
              <b>{{ calculateDiscountRate(deal) }}%</b> de desconto
            </span>
          </div>
        </div>
      </v-row>
      <v-row align="center" justify="space-between" class="py-2">
        <div class="align-self-start">
          <h5 class="card-title-deal">
            {{ maxDebit(deal.operations.filter((i) => i.active)).product }}
          </h5>
          <p
            class="mb-0 card-p"
            v-if="deal.operations.filter((i) => i.active).length > 1"
          >
            e mais outra dívida
          </p>
          <p class="mb-0 card-p">
            {{
              maxDebit(deal.operations.filter((i) => i.active)).days_of_delay
                | value
            }}
            dias em atraso
          </p>
        </div>
        <div
          v-if="dontShowDiscount(deal) || !incashProposal(deal)"
          class="pt-0 align-self-start card-details"
        >
          <h2 class="card-value-deal-more-discount">
            {{ totalDebit(deal) | money }}
          </h2>
        </div>
        <div v-else class="align-self-start card-details py-0">
          <h6 v-if="isDebitValueVisible(deal)" class="card-value-deal">
            De
            {{
              incashProposal(deal)?.operations_value ?? totalDebit(deal) | money
            }}
          </h6>
          <h2 class="card-value-deal-more-discount">
            {{ incashProposal(deal).down_payment | money }}
          </h2>
        </div>
      </v-row>
      <v-row
        align="center"
        class="origin-line"
        v-if="
          getAssignor(deal) || (!dontShowDiscount(deal) && incashProposal(deal))
        "
      >
        <div
          v-if="getAssignor(deal)"
          class="d-flex flex-column justify-start align-start"
          style="line-height: 15px"
        >
          <span class="card-p"><b>Origem:</b> {{ getAssignor(deal) }}</span>
        </div>
        <div
          justify="end"
          v-if="!dontShowDiscount(deal) && incashProposal(deal)"
          class="d-flex flex-column ml-auto"
          :class="$vuetify.breakpoint.xs ? 'align-start' : 'align-end'"
        >
          <span class="payment-type">
            <b>
              {{ paymentType() }}
            </b>
          </span>
        </div>
      </v-row>
      <v-row v-if="activePrePayment(deal)" align="center">
        <v-btn
          class="text-center qj-btn qj-bg-primary qj-p-btn ml-auto"
          @mousedown.stop
          @touchend.stop
          @click.stop="nextToParceleJa(deal)"
        >
          <div class="select-deal-button">Continuar Negociação</div>
        </v-btn>
      </v-row>
      <v-row v-else align="center" justify="end">
        <div class="d-flex justify-start" v-if="dontShowDiscount(deal)">
          <v-btn
            v-if="incashProposal(deal)"
            class="text-center qj-btn qj-bg-primary qj-p-btn"
            @mousedown.stop
            @touchend.stop
            @click.stop="
              next(deal.id, {
                meta: { showMenu: true, skipToHighlightOne: false },
              })
            "
          >
            <div class="select-deal-button">Negociar</div>
          </v-btn>
        </div>
        <div v-else class="d-flex justify-end" style="width: 100%">
          <v-btn
            v-if="incashProposal(deal) && getOperatingHours(deal)"
            class="qj-btn qj-p-btn mr-auto"
            color="#A9E4EB"
            @mousedown.stop
            @touchend.stop
            @click.stop="goToProposalHighLightOne()"
          >
            <div class="black--text select-deal-button">
              {{ proposalCondition() }}
            </div>
          </v-btn>
          <v-btn
            class="black--text qj-btn qj-p-btn qj-bg-primary"
            @mousedown.stop
            @touchend.stop
            @click.stop="
              next(deal.id, {
                meta: { showMenu: true, skipToHighlightOne: false },
              })
            "
          >
            <div class="select-deal-button">
              {{ getSelectDealButtonText(deal) }}
            </div>
          </v-btn>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import util from "@/assets/js/util";
export default {
  props: {
    deal: {
      type: Object,
    },
    calculateDiscountRate: {
      type: Function,
    },
    dontShowDiscount: {
      type: Function,
    },
    incashProposal: {
      type: Function,
    },
    maxDebit: {
      type: Function,
    },
    getAssignor: {
      type: Function,
    },
    doShowAssignorBand: {
      type: Function,
    },
    next: {
      type: Function,
    },
    isDebitValueVisible: {
      type: Function,
    },
    getSelectDealButtonText: {
      type: Function,
    },
    totalDebit: {},
    getAssignorLogo: { type: Function },
  },
  created() {
    this.setBestProposal();
    setInterval(() => {
      this.now = moment();
    }, 1000);
  },
  data() {
    return {
      now: moment(),
      bestProposal: null,
    };
  },
  methods: {
    activePrePayment(deal) {
      return [
        "WAITING_PIN_VALIDATION",
        "WAITING_PROPOSAL_CONFIRMATION",
      ].includes(deal.prePayment?.quiteja_status);
    },
    async nextToParceleJa(deal) {
      this.next(deal.id);
    },
    setBestProposal() {
      if (!this.deal?.proposals) {
        this.bestProposal = null;
        return;
      }
      this.bestProposal = this.deal?.proposals?.reduce((lowest, current) => {
        if (!lowest) return current;
        return current.highlight < lowest.highlight ? current : lowest;
      }, null);
      if (!this.bestProposal) {
        this.bestProposal =
          this.deal?.proposals?.find(
            (proposal) => proposal.payment_type === "IN_CASH"
          ) ??
          this.deal?.proposals[0] ??
          null;
      }
    },
    countDown(time) {
      function zero(x) {
        if (x < 10) {
          x = "0" + x;
        }
        return x;
      }
      let expires = moment(new Date(time));
      let expiresIn = moment.duration(expires.diff(this.now));
      if (expiresIn.days() >= 1) return "Por tempo limitado";
      let hours = zero(expiresIn.hours());
      let minutes = zero(expiresIn.minutes());
      let seconds = zero(expiresIn.seconds());
      return `Oferta acaba em ${hours}:${minutes}:${seconds}`;
    },
    proposalNotExpired(proposal) {
      let proposalExpiresIn = moment(proposal.expires_in);
      return proposalExpiresIn.diff(this.now) > 0;
    },
    expiresIn24hours(expireIn) {
      let expires = moment(expireIn);
      let expiresIn = moment.duration(expires.diff(this.now));
      if (expiresIn.days() >= 1) return false;
      return true;
    },
    proposalCondition() {
      if (this.bestProposal?.agreement_type == "REFINANCE")
        return "Refinanciar";
      else if (this.bestProposal?.agreement_type == "REGULARIZE")
        return "Regularizar";
      else if (
        this.bestProposal?.agreement_type == "PAY_OFF" &&
        this.bestProposal?.payment_type == "INSTALLMENT"
      )
        return "Parcelar";
      else return "Pagar à vista";
    },
    paymentType() {
      if (this.bestProposal?.agreement_type == "REFINANCE") return "Parcelado";
      else if (this.bestProposal?.agreement_type == "REGULARIZE")
        return this.bestProposal?.alias;
      else return "À vista";
    },
    getOperatingHours(deal) {
      let operatingHours = util.getConfigValue(
        deal.creditor.config_prefix + "operating_hours"
      );
      if (!operatingHours) return true;
      operatingHours = operatingHours.split("-");
      const initOperating = moment().set({
        hour: operatingHours[0].split(":")[0],
        minute: operatingHours[0].split(":")[1],
        second: 0,
        millisecond: 0,
      });
      const finalOperating = moment().set({
        hour: operatingHours[1].split(":")[0],
        minute: operatingHours[1].split(":")[1],
        second: 0,
        millisecond: 0,
      });

      return this.now.isBetween(initOperating, finalOperating, null, "[]");
    },
    goToProposalHighLightOne() {
      this.next(this.deal.id, {
        meta: { showMenu: true, skipToHighlightOne: true },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.qj-card-outline {
  padding: 16px 9px;
  margin: 16px 0;

  .row {
    margin: 0px !important;

    .card-p {
      font-size: 11px;
      line-height: normal;
    }

    &.origin-line {
      margin-bottom: 16px !important;

      .card-p {
        font-size: 12px;

        b {
          font-weight: 700;
        }
      }
    }

    button {
      border-radius: 10px;
      margin: 0px;
      width: 47%;
      font-weight: 700 !important;
    }

    & > div {
      width: 50%;
    }

    .payment-type {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: end;
      align-self: end;
    }
  }
}

.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe2c2;
  border-radius: 0px 0px 7px 7px;

  span {
    color: #ec7000;
    font-weight: 800;
    font-size: 12px;
    line-height: 15px;
  }
}

.deals-debts-pannels {
  width: calc(100% + 32px) !important;

  &__pannel {
    margin-bottom: 10px;

    button {
      box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.25);
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
      color: #00e58d;

      .v-expansion-panel-header__icon {
        margin-left: 10px !important;
      }
    }

    &::before {
      box-shadow: none;
      border: none;
    }

    &::after {
      border: none;
    }
  }
}

.tab {
  overflow: hidden;
  background-color: #e5e5e5;
  border-radius: 100px;
  margin-top: 7px;
}

.tab button {
  width: 50%;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 13px 0;
  /* transition: 0.3s; */
  font-size: 14px;
  line-height: 17px;
}

.tab button.active {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  margin: 5px;
  padding: 8px 0;
  width: calc(50% - 10px);
  font-weight: bold;
}

.tabcontent {
  margin-top: 10px;
  display: none;
}

.card-title-deal {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: normal;
  white-space: nowrap;
  max-width: 234px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 320px) {
    font-size: 11px;
  }
}

.card-origin {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  @media (max-width: 320px) {
    font-size: 12px;
  }
}

.card-p {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
}

.card-a {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: #00606a;
}

.card-details {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
}

.card-value-deal {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 111.9%;
  text-align: right;
  color: #212121;
  text-decoration-line: line-through;

  @media (max-width: 320px) {
    font-size: 12px;
  }
}

.card-value-deal-more-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
  text-align: right;
  color: #212121;

  @media (max-width: 320px) {
    font-size: 14px;
  }
}

.card-description-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  color: #212121;
}

.card-btn div {
  margin-right: auto;
}

.card-tag-state-wake-up {
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  padding: 7px 13px;
  border-radius: 100px;
  width: max-content;
  margin-left: auto;
}

.card-tag-state-wake-up b {
  color: inherit;
}

.card-tag-state-wake-up.tag-green {
  background: #cafce9;
  color: #000000;
}

.card-tag-state-wake-up.tag-yellow {
  background: #f9fcca;
  color: #b87f0e;
}

.card-tag-state-wake-up.tag-blue {
  background: #d1e6fc;
  color: #3876b5;
}

.card-tag-state-wake-up.tag-orange {
  background: #ffe7d8;
  color: #ff8533;
}

.card-tag-state-wake-up.tag-red {
  background: #ffeaea;
  color: #b83636;
}

.card-tag-state-wake-up.tag-grey {
  background: #e5e5e5;
  color: #000000;
}

.card-tag-state-wake-up.tag-brown {
  background: #efebe9;
  color: #3e2723;
}

.qj-card-msg {
  padding: 10px;
  background: #f6f6f6;
  border-radius: 7px;
}

.qj-operation-assignor {
  font-size: 16px;
  font-weight: 500;
}

.assignor-band {
  background-color: #f6f6f6;
  border-radius: 7px;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span:last-child {
    display: flex;
    align-items: center;
  }

  img {
    min-height: 16px;
    max-height: 20px;
  }

  @media (max-width: 320px) {
    width: 90%;
    line-height: 1.2;
    align-items: center;

    span:last-child {
      max-width: 75%;
      text-align: end;
      justify-content: end;
    }
  }
}

.no-bg {
  &::before {
    background-color: transparent;
  }
}

.qj-tab {
  font-size: 14px;
  font-weight: 600 !important;
}

.qj-tabs {
  @media (min-width: 600px) {
    width: 520px !important;
    margin-left: -32px;
  }

  @media (max-width: 600px) {
    width: 100vw !important;
    margin-left: -16px;
  }

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.qj-tag-discount {
  margin-top: 2.5px;
}

.select-deal-button {
  @media (max-width: 320px) {
    font-size: 12px;
  }
}
</style>
