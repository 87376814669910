<template>
  <div>
    <v-row class="align-content-start">
      <v-col cols="12" class="pt-6">
        <h1 class="qj-h1 mb-2" v-if="userInfo.customer_type == 'PERSONAL'">
          {{ userInfo.name }},
        </h1>
        <h1 class="qj-h1 mb-2" v-else>Olá,</h1>
        <p class="mb-0 qj-p">
          Para continuar a validação do seu acesso, informe a sua
          <b>data de nascimento.</b>
        </p>
      </v-col>
      <!-- Input birthday -->
      <v-col cols="12">
        <v-text-field
          dense
          outlined
          required
          single-line
          autofocus
          hide-details
          color="#00E58D"
          prepend-inner-icon="mdi-calendar"
          class="qj-date-input"
          inputmode="numeric"
          placeholder="__/__/____"
          v-mask="'##/##/####'"
          v-model="date"
          @keyup="send"
          data-hj-allow
        >
        </v-text-field>
        <span class="phone-sample">Ex: 00/00/0000</span>
      </v-col>

      <v-col cols="12" class="pt-0 flex-content centered text-center">
        <v-btn
          class="rounded-lg qj-btn btn-cpf qj-bg-primary"
          :loading="disabled"
          :disabled="disabled || date.length < 10"
          @click="send"
        >
          Prosseguir
        </v-btn>
      </v-col>
      <v-alert
        text
        color="red"
        transition="scale-transition"
        class="qj-alert-error text-center"
        :value="error"
      >
        Data de nascimento inválida.
        <br />Verifique e tente novamente.
      </v-alert>
    </v-row>
    <!-- Footer -->
    <qj-footer view="birthday" />
    <!-- Dialog -->
    <DialogLoadingDebts
      :dialogLoadingDebts="dialog"
      @closeDialogLoadingDebts="close"
      @policy="$emit('policy', $event)"
      @debitsLoaded="$emit('debitsLoaded', $event)"
    />
    <DialogLoginError :dialogLoginError="dialogLoginError" />
  </div>
</template>

<script>
import Authentication from "./authentication";
import store from "@/store";
import { mapActions, mapMutations } from "vuex";
import environment from "@/assets/js/environment";
import DialogLoadingDebts from "../components/modals/DialogLoadingDebts";
import DialogLoginError from "../components/modals/DialogLoginError";

export default {
  components: {
    DialogLoadingDebts,
    DialogLoginError,
  },
  data: (vm) => ({
    date: "",
    userInfo: store.getters["authentication/getUserInfo"],
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    modal: false,
    error: false,
    dialog: false,
    disabled: false,
    formattedAnswer: "",
    activePicker: null,
    dialogLoginError: false,
  }),
  beforeRouteEnter(to, from, next) {
    if (
      store.getters["authentication/getTokenSms"] &&
      !from.meta.requiresAuth
    ) {
      next();
    } else {
      next({ name: "Authentication" });
    }
  },
  watch: {
    modal(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    ...mapActions("authentication", ["ActionSetUserInfo", "validateChallenge"]),
    ...mapActions("authentication", {
      setCookie: "setAuthTokenToCookie",
    }),
    ...mapMutations("authentication", {
      setChallengeResultObject: "setChallengeResultObject",
      setUserInfo: "setUserInfo",
    }),
    send() {
      if (!this.date) {
        return;
      }

      if (this.date.length < 10) {
        return;
      }

      if (this.disabled) {
        return;
      }

      console.debug("Enviando a data de nascimento", this.date);

      this.error = false;
      this.disabled = true;
      let payload = {
        answer: this.date.split("/").reverse().join("-"),
        type: "BIRTHDAY",
        token: store.getters["authentication/getTokenSms"],
      };

      this.validateChallenge(payload)
        .then(() => {
          this.challengeSuccess(payload);
        })
        .catch((error) => {
          console.error("Não foi possível fazer o login.", error);

          // Erro 401: challenge inválido
          if (error.response && error.response.status == 401) {
            this.dialogLoginError = true;
            return;
          }

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => (this.disabled = false));
    },
    showLoginErrorModal() {
      this.dialogLoginError = true;
    },
    challengeSuccess(payload) {
      let resultTokenObject = this.$jwt.decode(
        store.getters["authentication/getChallengeResultToken"]
      );
      if (resultTokenObject.attempts >= 3) {
        this.showLoginErrorModal();
      } else if (resultTokenObject.attempts >= 0) {
        this.error = true;
      }
      if (resultTokenObject.token_type == "AUTH") {
        this.setUserInfo(resultTokenObject.customer);
        this.setCookie({ payload: null, vm: this });

        this.createTagManagerEvent({ event: "Login" });

        this.dialog = true;
      } else if (resultTokenObject.token_type == "PRE_AUTH") {
        this.setChallengeResultObject(resultTokenObject);
        Authentication.resolveChallenge(
          store.getters["authentication/getChallengeResultObject"].challenge,
          payload,
          this.$route.query.continue
        );
      }
    },
    save(date) {
      this.$refs.dialog.save(date);
      this.dateFormatted = date;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      if (date.indexOf("-") !== -1) {
        return date;
      } else {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    close() {
      this.dialog = false;
      this.$router.push({ name: "Deals" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
};
</script>

<style lang="scss" scoped>
.qj-birthday {
  margin: auto;
  max-width: 300px;
}
.qj-date-input {
  font-size: 14px;
}
.phone-sample {
  margin-top: 5px;
  display: block;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b4b4b4;
}
</style>
