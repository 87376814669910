<template>
  <div style="padding-bottom: 90px">
    <!-- Body -->
    <v-row class="align-content-start">
      <!-- Title -->
      <v-col cols="12" class="pb-0 pt-0">
        <p class="qj-p mb-0">
          Verifique as informações de sua negociação e confirme
        </p>
      </v-col>
      <v-col cols="12" v-bind:id="'proposal-' + proposal.id">
        <div
          class="qj-card-outline mb-0"
          :style="generateProposalBoxStyle(proposal)"
        >
          <ProposalCard
            :proposal="proposal"
            :proposals="[]"
            :deal="deal"
            :do-show-next-button="false"
            :showAlias="true"
          />
        </div>
      </v-col>

      <v-col v-if="hasProposalConditions" class="qj-proposal-conditions pt-0">
        <a
          class="qj-link qj-color-secondary"
          @click="dialogProposalConditions = true"
        >
          {{
            typeOfTerms() == "itau"
              ? "Informações importantes"
              : "Condições da oferta"
          }}
        </a>
      </v-col>
      <v-col cols="12" v-if="proposal.payment_methods">
        <ul class="qj-list rounded-lg no-border">
          <li class="qj-iten-list d-flex justify-space-between align-center">
            <div>
              <p class="qj-p mb-0">
                Método de pagamento {{ paymentTypeText(proposal) }}
              </p>
              <b>{{
                paymentMethods?.find(
                  (method) => method.value == proposal.payment_method
                ).text
              }}</b>
            </div>
            <div>
              <v-btn text plain @click="openChangePaymentMethod()">
                <span
                  class="text-capitalize font-weight-bold text-body-2 text--decoration-none"
                  >Alterar</span
                >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </li>
        </ul>
      </v-col>

      <!--  -->
      <DetailsList
        :deal="deal"
        :propsExtraProposalInfos="extraProposalInfos"
        :propsRegularizeDetails="regularizeDetails"
        :proposal="proposal"
        :callSumary="false"
        v-if="isDetailsVisible"
      />
      <!-- Informações sobre IOF detalhadas para propostas regulares -->
      <v-col
        cols="12"
        class="pt-0 qj-installment-details"
        v-if="isInstallmentDetailsVisible && !isProposalIrregular"
      >
        <ul class="qj-list">
          <li
            class="justify-center d-flex flex-column align-center text-center"
          >
            <p class="qj-p pt-2 mb-1 font-weight-bold">Valor da Parcela</p>
            <div class="observation">
              O valor de
              <span class="font-weight-bold">{{ proposal.iof | money }}</span>
              referente ao I.O.F. será diluído nas
              {{ proposal.installments_count_1 }} parcelas do seu acordo
            </div>
          </li>
          <li
            class="justify-space-between qj-iten-list-flex qj-bt"
            v-if="proposal.installments_count_1"
          >
            <p class="qj-p mb-0">Valor da Parcela</p>
            <span>{{ proposal.installment_1 | money }}</span>
          </li>
          <li
            class="justify-space-between qj-iten-list-flex qj-bt"
            v-if="proposal.installments_count_1"
          >
            <p class="qj-p mb-0">I.O.F. Parcial</p>
            <span>{{ iofRegular.partial | money }}</span>
          </li>
          <li
            class="justify-space-between qj-iten-list-flex qj-bt"
            v-if="proposal.installments_count_1"
          >
            <p class="qj-p mb-0 font-weight-bold">Valor da Parcela + I.O.F.</p>
            <span class="font-weight-bold">
              {{ iofRegular.installment | money }}
            </span>
          </li>
        </ul>
      </v-col>

      <!-- Informações sobre IOF detalhadas para propostas irregulares -->
      <v-col class="iof-info text-caption pt-0" v-if="isProposalIrregular">
        *O valor de I.O.F. será
        <span class="font-weight-bold">{{ iofIrregular.partial | money }}</span>
        por parcela.
      </v-col>

      <v-col
        class="iof-info text-caption pt-0"
        v-if="this.proposal.iof_description"
      >
        *{{ this.proposal.iof_description }}
      </v-col>
      <v-col
        class="qj-p pt-2 pb-4"
        v-if="totalRemaining && remaining_installments"
      >
        {{
          `Resta${
            remaining_installments > 1 ? "m" : ""
          } ${remaining_installments} parcela${
            remaining_installments > 1 ? "s" : ""
          }, 
            no valor total de ${total_remaining_as_money} a serem negociadas.
           A restrição em seu nome só poderá ser retirada após o pagamento total
          das parcelas em atraso.`
        }}
      </v-col>

      <!-- Informações sobre a conta-corrente. -->
      <v-col cols="12" class="pt-0" v-if="bankAccounts?.[0] ?? null">
        <ul class="qj-list">
          <li class="qj-iten-list-flex" v-if="typeOfTerms() == 'itau'">
            <p class="qj-p mb-0">
              Contas para débito das demais parcelas
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="qj-icon-list" v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span
                  >Poderá ser debitado em qualquer uma das contas listadas</span
                >
              </v-tooltip>
            </p>
          </li>
          <li
            v-for="bankAccount in bankAccounts"
            :key="bankAccount.branch_code + bankAccount.account_number"
            :class="
              (bankAccount.branch_code != bankAccounts?.[0]?.branch_code &&
                'qj-bt ') + ' qj-iten-list-flex'
            "
          >
            <p class="qj-p mb-0">
              Agência: <b>{{ bankAccount | bankAccountBranch }}</b> / Conta:
              <b>{{ bankAccount | bankAccountNumber }}</b>
            </p>
          </li>
        </ul>
      </v-col>

      <!-- Termos do usa das contas. -->
      <v-col
        cols="12"
        :class="'pt-0' + (validBanc ? '' : ' pb-1')"
        v-if="hasDifferentTerms && (bankAccounts?.[0] ?? null)"
      >
        <ul
          class="qj-list"
          :style="validBanc ? '' : 'background: #FFEAEA !important;'"
        >
          <li class="qj-iten-list">
            <v-checkbox
              hide-details
              :class="'pt-0 mt-0 qj-checkbox' + (termsBanc ? '' : '-invalid')"
              v-model="termsBanc"
              @click="validateTermsBanc()"
            >
              <template v-slot:label>
                <div
                  class="qj-checkbox-label"
                  :style="termsBanc ? '' : 'color: #B83636 !important;'"
                >
                  <div
                    v-if="showDebitText()"
                    :style="termsBanc ? '' : 'color: #B83636 !important;'"
                    style="text-align: justify; letter-spacing: -0.16px"
                  >
                    <b :style="termsBanc ? '' : 'color: #B83636 !important;'">
                      <span v-html="debitAlertText"> </span
                    ></b>
                  </div>
                </div>
              </template>
            </v-checkbox>
          </li>
        </ul>
      </v-col>
      <v-col
        cols="12"
        class="pt-0"
        v-if="hasDifferentTerms && (bankAccounts?.[0] ?? null)"
        v-show="!validBanc"
      >
        <p class="qj-p mb-0" style="color: #b83636 !important">
          É necessário concordar com os termos para prosseguir.
        </p>
      </v-col>
      <v-col cols="12" :class="'pt-0'">
        <ul class="qj-list">
          <template v-if="!negotiation?.prePaymentCreditCard">
            <li class="qj-iten-list qj-bb" v-if="proposal.installments_count_1">
              <p class="qj-p mb-0">
                Data de pagamento {{ paymentTypeText(proposal) }}
              </p>
              <b>{{ date | data }}</b>
            </li>
            <li class="qj-iten-list qj-bb">
              <p class="qj-p mb-0">Data do {{ showPaymentType(proposal) }}</p>
              <b v-if="date && proposal.payment_type == 'IN_CASH'">
                <b>{{ date | data }}</b>
              </b>
              <b
                v-else-if="
                  remainingInstallmentsText() &&
                  proposal.payment_method == 'FIRST_BOLETO_OTHERS_CREDIT'
                "
              >
                {{ remainingInstallmentsText() }}
              </b>
              <b v-else>
                {{ (proposal.installments_count_1 ? "Todo d" : "D") + "ia " }}
                {{ proposalDueDay() }}
                <span
                  v-if="extraExpireMessage"
                  v-html="extraExpireMessage"
                ></span>
              </b>
            </li>
          </template>
          <li v-if="phone" class="qj-iten-list qj-bb">
            <p class="qj-p mb-0">Telefone para contato</p>
            <b>{{ phone.value | cellphone }}</b>
          </li>
          <li v-if="email" class="qj-iten-list">
            <p class="qj-p mb-0">
              E-mail para receber
              {{ doShowBankSlip("os boletos", "as informações do acordo") }}
            </p>
            <b class="break-word">{{ email }}</b>
          </li>
        </ul>
      </v-col>

      <v-col v-if="!email" class="form-email">
        <p v-if="proposal.payment_method == 'BOLETO'" class="qj-p mb-0">
          Informe um e-mail para receber os boletos
        </p>
        <p v-else class="qj-p mb-0">
          Informe um e-mail para receber as informações do acordo
        </p>

        <v-form ref="formEmail" @submit.prevent="next()">
          <v-text-field
            type="email"
            autocomplete="email"
            dense
            outlined
            required
            single-line
            ref="email"
            label="E-mail"
            class="qj-input"
            :rules="rules.email"
            v-model="newEmail"
          ></v-text-field>
        </v-form>
      </v-col>

      <!-- Termos de uso -->
      <v-col
        cols="12"
        :class="'pt-0' + (termsConfirmed ? '' : ' pb-1')"
        v-show="!hideTermsOfUse"
      >
        <ul
          class="qj-list"
          :style="!termsConfirmed && 'background: #FFEAEA !important;'"
        >
          <li class="qj-iten-list">
            <v-checkbox
              hide-details
              :class="'pt-0 mt-0 qj-checkbox' + (!termsConfirmed && '-invalid')"
              v-model="termsOfUse"
              @click="validateTerms()"
            >
              <template v-slot:label>
                <div
                  class="qj-checkbox-label"
                  :style="!termsConfirmed && 'color: #B83636 !important;'"
                >
                  <div v-if="acceptance" style="letter-spacing: -0.16px">
                    <span v-html="acceptance"> </span>
                    <span v-if="!hideLinkTerms"
                      >, e os
                      <a
                        href="javascript:void(0);"
                        class="qj-link qj-color-secondary"
                        :style="!termsConfirmed && 'color: #B83636 !important;'"
                        @click="
                          dialogTermsOfUse = true;
                          termsOfUse = !termsOfUse;
                        "
                        >{{ getCreditorTermsLabel }}</a
                      > </span
                    >.
                  </div>
                  <div
                    v-else-if="hasDifferentTerms"
                    style="text-align: justify; letter-spacing: -0.16px"
                  >
                    Ao confirmar essa proposta, declaro que li e concordo com as
                    informações importantes, os detalhes dessa
                    {{ hideLinkTerms ? "negociação" : "negociação, e os " }}
                    <a
                      v-if="!hideLinkTerms"
                      href="javascript:void(0);"
                      class="qj-link qj-color-secondary"
                      :style="!termsConfirmed && 'color: #B83636 !important;'"
                      @click="
                        dialogTermsOfUse = true;
                        termsOfUse = !termsOfUse;
                      "
                      >{{ getCreditorTermsLabel }}</a
                    >.
                  </div>
                  <div v-else>
                    Li e concordo com os
                    <a
                      href="javascript:void(0);"
                      class="qj-link qj-color-secondary"
                      :style="!termsConfirmed && 'color: #B83636 !important;'"
                      @click="openDialogTerms()"
                    >
                      {{ getCreditorTermsLabel }}</a
                    >.
                  </div>
                </div>
              </template>
            </v-checkbox>
          </li>
        </ul>
      </v-col>
      <v-col cols="12" class="pt-0" v-show="!termsConfirmed">
        <p class="qj-p mb-0" style="color: #b83636 !important">
          É necessário concordar com os termos para prosseguir.
        </p>
      </v-col>
      <v-col
        cols="12"
        class="px-4 pt-0 qj-status-alert-info d-flex align-end"
        v-if="deal"
      >
        <qj-alert-info
          name="proposal-confirm-before-footer"
          :creditorId="deal.creditor_id"
          :deal="deal"
          class=""
        />
      </v-col>
    </v-row>
    <v-row v-if="proposal.payment_method == 'PARCELE_JA'">
      <v-col class="pt-0">
        <span class="qj-p">
          Lembre-se, os <b>assessores do Parcele Já</b> poderão entrar em
          contato para confirmar o <b>Pin da operação.</b> Você poderá
          encontrá-lo na sua <b>fatura do cartão de crédito</b> ou
          <b>app do seu banco.</b>
        </span>
      </v-col>
    </v-row>
    <!-- Footer -->
    <qj-footer
      :forceDownload="forceContractDownload"
      :loading="confirmLoading"
      type="confirm"
      :fixed="true"
      @send="next"
      view="negociacao/confirmar"
    />

    <!-- :disabled="!canConfirm" -->
    <!-- Dialog Wait Minute -->
    <DialogWaitMinute
      v-show="dialogNext"
      :proposal="proposal"
      :form="form"
      :dialog="dialogNext"
      :contacts="contacts"
      :addresse="addresse"
    />
    <!-- Dialog Contract Clauses -->
    <DialogTermsOfUse
      v-show="!hideTermsOfUse"
      :url="url"
      :base64Code="base64Code"
      :terms="termsOfUse"
      :footer="true"
      :forceDownload="forceContractDownload"
      :type="typeOfTerms()"
      :hasDifferentTerms="hasDifferentTerms"
      :creditorTermsLabel="getCreditorTermsLabel"
      :id="$route.query.id"
      :dialogTermsOfUse="dialogTermsOfUse"
      @next="next"
      @setTerms="termsOfUse = !termsOfUse"
      @hide="hideDialogTermsOfUse($event)"
      @close="dialogTermsOfUse = false"
    />
    <!-- Condições da proposta (oferta) -->
    <DialogProposalConditions
      :dialogProposalConditions="dialogProposalConditions"
      :proposalId="proposal.id"
      @close="dialogProposalConditions = false"
    />

    <DialogAbandonedProposal
      :alert="dialogAbandonedProposal"
      :dealId="proposal.deal_id"
      @close="dialogAbandonedProposal = false"
    />

    <DialogCheckTerms
      :bankAccounts="bankAccounts"
      :hideLinkTerms="hideLinkTerms"
      :terms="termsOfUse"
      :hasDifferentTerms="hasDifferentTerms"
      :forceDownload="forceContractDownload"
      :creditorTermsLabel="getCreditorTermsLabel"
      :debitAlertText="debitAlertText"
      :showDebitText="showDebitText()"
      @dialogTermsOfUse="dialogTermsOfUse = true"
      @openDialogTerms="openDialogTerms"
      @confirmAll="confirmAll"
      ref="dialogCheckTerms"
    />
    <DialogAsync ref="DialogAsync" />
    <DialogChangePaymentMethod
      :paymentMethods="proposal.payment_methods"
      ref="DialogChangePaymentMethod"
      @updatePaymentMethod="updatePaymentMethod"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { CreditorId } from "@/constants";
import rules from "@/assets/js/rules";
import store from "@/store";
import Deal from "@/modules/deal";
import Proposal from "@/modules/proposal";
import util from "@/assets/js/util";
import serviceUtil from "@/services/util";
import ProposalService from "@/services/proposal";
import DialogProposalConditions from "@/components/DialogProposalConditions";
import DialogTermsOfUse from "@/components/DialogTermsOfUse";
import DetailsList from "@/components/DetailsList";
import DialogWaitMinute from "../components/DialogWaitMinute";
import DialogAbandonedProposal from "../components/DialogAbandonedProposal";
import DialogChangePaymentMethod from "../components/DialogChangePaymentMethod";
import DialogCheckTerms from "../components/DialogCheckTerms.vue";
import { createTagManagerEvent, feeTable } from "@/utils";
import ProposalCard from "../components/proposalCard.vue";
import DialogAsync from "@/components/DialogAsync.vue";
import html2canvas from "html2canvas";

export default {
  components: {
    DialogAsync,
    DialogProposalConditions,
    DialogWaitMinute,
    DialogTermsOfUse,
    DialogAbandonedProposal,
    DialogCheckTerms,
    DetailsList,
    ProposalCard,
    DialogChangePaymentMethod,
  },
  data: () => ({
    extraProposalInfos: null,
    regularizeDetails: null,
    dialogAbandonedProposal: false,
    addresse: null,
    deal: null,
    extraExpireMessage: null,
    otherTerms: null,
    /** Informações da conta-corrente. */
    bankAccount: null,
    /**
     * Indica o tipo do I.O.F.
     * - first_installment: somente na primeira parcela
     * - divided: dividido em cada parcela
     */
    iofType: null,
    /**
     * E-mail que deve ser informado pelo cliente caso ele não tenha
     * passado pela tela anterior. Por exemplo, quando a proposta é
     * do tipo "carrinho abandonado" e ele foi redirecionado direto para a tela
     * de confirmação de proposta.
     */
    newEmail: null,
    confirmLoading: false,
    /** Dia de vencimento das parcelas. */
    installmentDueDate: null,
    rules: rules,
    hideTermsOfUse: false,
    hideLinkTerms: false,
    termsConfirmed: true,
    termsOfUse: false,
    dialog: false,
    whatsapp: false,
    termsBanc: false,
    validBanc: true,
    dialogCET: false,
    dialogIOF: false,
    dialogNext: false,
    force_terms_oppening: false,
    dialogProposalConditions: false,
    dialogTermsOfUse: false,
    metadata: null,
    url: "",
    base64Code: "",
    date: "",
    email: "",
    phone: "",
    bankAccounts: [],
    form: {},
    contacts: {},
    proposal: {},
    totalRemaining: null,
    forceContractDownload: false,
    remaining_installments: null,
    hasDifferentTerms: false,
    debitAlertText: "",
    acceptance: "",
    paymentMethods: null,
  }),
  beforeCreate() {
    this.$emit("loading", true);
  },
  async created() {
    let proposalId = null;
    if (this.$route.query.id) {
      proposalId = util.base64Decode(this.$route.query.id);
    } else if (
      !this.$route.params.date &&
      !this.$route.params.isParceleJa &&
      !this.$route.params.proposal
    ) {
      this.$router.push({ name: "Deals" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });

      return;
    }
    let negotiation = store.getters["negotiation/getNegotiation"];

    this.proposal = util.getProposal(proposalId);
    if (!this.proposal) {
      console.info(
        `proposta ${proposalId} nao encontrada, redirecionando usuario para a home`
      );
      this.$router.push({ name: "Deals" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
      return;
    }

    this.date = this.$route.params.date ?? this.proposal.down_payment_due_date;
    this.email = this.$route.params.email || negotiation.email;
    this.phone = this.$route.params.phone || negotiation.phone;

    this.bankAccounts = [util.extractBankAccount(negotiation.account)];
    this.whatsapp = this.$route.params.whatsapp == "true" ? true : false;
    this.addresse = this.$route.params.addresse;

    let configPrefix = util.getPrefixByProposal(proposalId);

    this.iofType =
      util.getConfig()[configPrefix + "iof_type"] || "first_installment";

    await this.getSumary();
    let dueDate = moment(this.date);
    this.installmentDueDate = negotiation.installmentDueDate || dueDate.date();
    this.extraExpireMessage =
      this.getAlert("extra-expiration-message", this.proposal.creditor_id)
        ?.content || null;
    this.dialogAbandonedProposal = this.$route.query.carrinho;
    if (this.typeOfTerms() != "itau") this.termsBanc = true;
    this.$emit("loading", false);
    this.checkPaymentMethods();
    this.acceptance = util.getConfig()[configPrefix + "terms_acceptance_label"];
    if (!this.deal) {
      this.deal = this.getDealByProposalId(proposalId);
    }
  },
  watch: {
    termsOfUse() {
      if (this.termsOfUse) this.termsConfirmed = true;
    },
  },
  methods: {
    ...mapMutations("alert", ["setModalAlertState", "setExactValueState"]),
    ...mapMutations("brokenDeal", ["setExclusiveCreditor"]),
    ...mapActions("parceleJa", {
      fetchParceleJaProposals: "getProposals",
      sendPrePayment: "sendPrePayment",
    }),
    async updatePaymentMethod($event) {
      this.proposal.payment_method = $event;
      this.$emit("loading", true);
      try {
        await this.getSumary();
      } finally {
        this.$emit("loading", false);
      }
    },
    paymentTypeText(proposal) {
      if (proposal.down_payment > 0) return "da entrada";
      else if (
        proposal.payment_type == "INSTALLMENT" &&
        proposal.down_payment <= 0
      )
        return "da 1ª parcela";
      return "";
    },
    async getSumary() {
      const configPrefix = util.getPrefixByProposal(this.proposal.id);
      const url = util.getConfig()[configPrefix + "proposal_summary_url"];
      if (!url) return;
      const payload = {
        proposal_id: this.proposal.id,
        payment_method: this.proposal.payment_method,
        due_date:
          this.$route.params.date ?? this.proposal.down_payment_due_date,
        metadata: this.metadata,
        email: this.email || null,
      };
      const options = {
        headers: {
          "Authentication-Token": store.getters["authentication/getToken"],
        },
      };
      try {
        const { data } = await serviceUtil.generic(
          url,
          "post",
          payload,
          options
        );
        this.hasDifferentTerms = true;
        this.url = data?.terms?.url ?? false;
        this.force_terms_oppening = data?.terms?.force_term_opening_on_check;
        this.base64Code = data?.terms?.base64 ?? false;
        this.hideLinkTerms = data?.terms?.hideLink ?? false;
        this.bankAccounts = data?.bank_accounts;
        this.extraProposalInfos = data?.extra_proposal_infos;
        this.regularizeDetails = data?.regularize_details;
        this.totalRemaining = data?.totalRemaining ?? false;
        this.forceContractDownload = data?.force_contract_download ?? false;
        this.remaining_installments = data?.remaining_installments ?? false;
        this.metadata = data?.metadata ?? null;
        this.negotiation.installmentDueDate =
          this.negotiation.installmentDueDate || data?.installment_due_date;
        if (data?.proposal) {
          this.proposal = Object.assign(this.proposal, data?.proposal);
        }
      } catch (error) {
        throw Error(error);
      }
    },
    openDialogTerms() {
      createTagManagerEvent({
        event: "dealClickedUseTerms",
      });
      this.dialogTermsOfUse = true;
      this.termsOfUse = !this.termsOfUse;
    },
    doShowBankSlip(bankSliptext, otherText) {
      let isBankSlip = this.proposal.payment_method == "BOLETO";
      let dealId = this.proposal.deal_id;

      if (isBankSlip && util.doShowBankSlip(dealId)) return bankSliptext;
      return otherText;
    },
    checkPaymentMethods() {
      const methods = [
        { value: "BOLETO", text: "Boleto" },
        { value: "FIRST_PIX_OTHERS_DEBIT", text: "Pix" },
        { value: "FIRST_PIX_OTHERS_BOLETO", text: "Pix" },
        { value: "FIRST_BOLETO_OTHERS_DEBIT", text: "Boleto" },
        { value: "PIX", text: "Pix" },
      ];
      const proposalPaymentMethods = this.proposal?.payment_methods?.split(",");

      if (!proposalPaymentMethods) return false;

      this.paymentMethods = methods.filter((method) => {
        return proposalPaymentMethods.includes(method.value);
      });
      if (this.paymentMethods) return true;
      return false;
    },
    showPaymentType(proposal) {
      let installments = proposal.installments_count_1;

      if (installments) return "pagamento das demais parcelas";
      if (!installments && util.doShowBankSlip(proposal.deal_id))
        return "pagamento do boleto";
      return "pagamento";
    },
    doShowExactDialog(proposalId) {
      let creditorPrefix = util.getPrefixByProposal(proposalId);
      if (!creditorPrefix) return;

      let doShow = util.getConfig()[creditorPrefix + "show_exact_dialog"];
      if (doShow) {
        setTimeout(() => {
          this.setExactValueState({
            showExactValue: true,
            exactValue: this.proposal.down_payment,
          });
        }, 100);
      }
      return;
    },
    async showCreditCardErrorDialog() {
      await this.$refs.DialogAsync.openAndWait({
        title: "Falha no pagamento",
        text: "Desculpe, não conseguimos validar o seu pagamento, por favor, verifique todas as informações do seu cartão de crédito e tente novamente.",
      });
      return;
    },
    confirmAll() {
      this.termsOfUse = true;
      this.termsBanc = true;
      this.$refs.dialogCheckTerms.dialog = false;
      setTimeout(() => {
        this.next();
      }, 0);
    },
    async next() {
      if (!this.termsBanc && this.bankAccounts?.[0]?.length > 0) {
        this.$vuetify.goTo(600);
        this.validBanc = false;
      }
      if (this.force_terms_oppening && !this.termsOfUse) {
        this.dialogTermsOfUse = true;
        return;
      } else if (!this.termsOfUse) {
        this.$refs.dialogCheckTerms.dialog = true;
        this.termsConfirmed = false;
        return;
      }
      this.confirmLoading = true;

      await this.captureScreenshot();

      if (this.forceContractDownload) {
        const linkSource = `data:application/pdf;base64,${this.base64Code}`;
        const downloadLink = document.createElement("a");
        const fileName = "contrato.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        // document.body.removeChild(downloadLink);
      }

      if (
        this.negotiation?.prePaymentCreditCard &&
        this.proposal?.payment_method === "PARCELE_JA"
      ) {
        try {
          await this.sendPrePayment(this.negotiation?.prePaymentCreditCard);
        } catch (e) {
          await this.showCreditCardErrorDialog();
          this.$router.replace({
            name: "CreditCardPayment",
            query: { id: this.$route.query.id },
          });
        }
      }
      let proposalId = util.base64Decode(this.$route.query.id);
      let email = this.email || this.newEmail;
      let contacts = [email];

      if (this.phone) {
        contacts.push(this.phone.value);
      }

      this.contacts = { contacts };
      this.form = {
        down_payment_due_date: this.date
          ? this.date + "T03:00:00.000Z"
          : undefined,
        id: proposalId,
        installment_due_date:
          this.proposal.installment_due_date ||
          store.getters["negotiation/getNegotiation"].installmentDueDate,
        status: "CONFIRMED",
      };

      let account = store.getters["negotiation/getNegotiation"].account;
      if (!util.isEmpty(account)) {
        this.form.bank_code = account.bank_code;
        this.form.branch_code = account.branch_code;
        this.form.branch_code_check_digit = account.branch_code_check_digit;
        this.form.account_number = account.account_number;
        this.form.account_number_check_digit =
          account.account_number_check_digit;
      }

      if (this.proposal.id) {
        this.createTagManagerEvent({
          event: "proposalConfirmed",
          operationsValue: this.proposal.operations_value,
          paymentMethod: this.proposal.payment_method,
          paymentType: this.proposal.payment_type,
          presentValue: this.proposal.operations_value - this.proposal.discount,
          fee: this.calculateFee(),
        });
      }
      this.setExclusiveCreditor(null);
      this.doShowExactDialog(proposalId);
      this.showAlertAfterProposalConfirmed();
      this.dialogNext = true;
    },
    /**
     * Verifica se existe o alerta "alert-after-proposal-confirmed".
     * Se existir então exibe o conteúdo do alerta.
     */
    showAlertAfterProposalConfirmed() {
      const alerts = this.getAlerts(
        "alert-after-proposal-confirmed",
        this.proposal.creditor_id
      );

      if (alerts.length == 0) {
        return;
      }

      console.debug("Exibindo o alerta", alerts?.[0]?.name);

      // Adiciona um delay para abrir a modal para que ela fique em cima da modal do
      // "Aguarde um instante... Estamos encaminhando a sua proposta"
      setTimeout(() => {
        this.setModalAlertState({
          open: true,
          content: alerts?.[0]?.content,
        });
      }, 100);
    },
    showDebitText() {
      let alert = this.getAlert(
        "automatic-debit-authorization",
        this.deal?.creditor_id
      );

      if (alert && alert?.content) {
        this.debitAlertText = alert.content;
        return true;
      }
      return false;
    },
    calculateFee() {
      const feeMultiplier = feeTable.get(this.proposal.creditor_id);
      const payment = this.proposal.down_payment
        ? this.proposal.down_payment
        : this.proposal.installment_1;
      const conversionValue = payment * feeMultiplier;
      return conversionValue;
    },
    hideDialogTermsOfUse(hide) {
      this.hideTermsOfUse = hide;
      this.termsOfUse = hide;
    },
    validateTerms() {
      if (this.force_terms_oppening) {
        this.dialogTermsOfUse = true;
      }
      if (!this.$refs.formEmail) {
        return;
      }
      if (!this.termsOfUse) {
        return;
      }
      this.$refs.formEmail.validate();
      this.$refs.email?.focus();
    },
    returnProposalBoxClass(proposal) {
      if (!proposal) return "";
      if (proposal?.incentive == "Máximo parcelamento") {
        return "incentive-color-purple";
      } else if (proposal?.incentive == "Parcelamento máximo") {
        return "incentive-color-purple";
      } else if (proposal?.incentive == "Oferta sem juros") {
        return "incentive-color-gray";
      } else if (
        proposal.payment_type == "IN_CASH" &&
        proposal?.discount_rate > 0.1 &&
        !this.proposalOfferType &&
        proposal.id == this.proposals?.[0]?.id &&
        !proposal.incentive
      ) {
        return "incentive-color-pink";
      } else {
        return null;
      }
    },
    generateProposalBoxStyle(proposal) {
      if (this.returnProposalBoxClass(proposal) || !proposal?.incentive)
        return null;
      return {
        borderColor: util.stringToColor(proposal.incentive) ?? "#853500",
      };
    },
    validateTermsBanc() {
      this.termsBanc != this.termsBanc;
      this.validBanc = true;
    },
    typeOfTerms() {
      let proposalId = util.base64Decode(this.$route.query.id);
      let prefix = util.getPrefixByProposal(proposalId);

      switch (prefix) {
        case "itau.":
          return "itau";
        default:
          return "default";
      }
    },
    hasCreditorProfile() {
      let profile = this.deal?.creditor_profile;

      if (!profile || !profile?.logo_url || !profile?.long_name) return false;
      return true;
    },
    remainingInstallmentsText() {
      let configPrefix = util.getPrefixByProposal(this.proposal.id);
      if (!configPrefix) return false;

      let config =
        util.getConfig()[configPrefix + "remaining_installments_text"];
      if (!config) return false;

      return config;
    },
    proposalDueDay() {
      if (this.proposal.installment_due_date)
        return this.proposal.installment_due_date;

      return this.installmentDueDate;
    },
    async captureScreenshot() {
      this.dialogCET = false;
      this.dialogIOF = false;
      this.dialogNext = false;
      this.dialogProposalConditions = false;
      this.dialogTermsOfUse = false;
      this.$refs.dialogCheckTerms.dialog = false;

      const element = document.getElementById("app");

      const images = element.getElementsByTagName("img");
      await Promise.all(
        Array.from(images).map((img) => {
          if (!img.complete) {
            return new Promise((resolve) => {
              img.addEventListener("load", resolve);
            });
          }
          return Promise.resolve();
        })
      );

      const canvas = await html2canvas(element);
      const imageData = canvas.toDataURL("image/png");

      let base64WithoutHeader = imageData.split(",")[1];
      let fileSize = Math.round((base64WithoutHeader.length * 3) / 4);

      let { deal_id, id } = this.proposal;
      let uploadFolders = ["screenshots", `deal-${deal_id}`, `proposal-${id}`];

      let registerPayload = {
        folders: uploadFolders,
        original_name: `screenshot-deal-${deal_id}-proposal-${id}`,
        content_type: "image/png",
        size: fileSize,
      };
      this.registerScreenShot(registerPayload, canvas, id);
      return;
    },
    openChangePaymentMethod() {
      this.$refs.DialogChangePaymentMethod.open(this.proposal.payment_method);
    },
    async registerScreenShot(registerPayload, canvas, proposal_id) {
      try {
        const { data } = await ProposalService.registerScreenshot(
          registerPayload,
          "public-read"
        );

        const binaryData = await new Promise((resolve) => {
          canvas.toBlob((blob) => {
            resolve(blob);
          }, "image/png");
        });

        const formData = new FormData();
        formData.append("key", data._storage.fields.key);
        formData.append("AWSAccessKeyId", data._storage.fields.AWSAccessKeyId);
        formData.append("acl", data._storage.fields.acl);
        formData.append("policy", data._storage.fields.policy);
        formData.append("signature", data._storage.fields.signature);
        formData.append("Content-Type", "image/png");
        formData.append("file", binaryData);

        await serviceUtil.generic(data._storage.url, "POST", formData);
        const reponse = await ProposalService.saveScreenshot({
          file_id: data.id,
          proposal_id: proposal_id,
        });
        console.info("Sucesso ao salvar screenshot");
        return reponse;
      } catch (error) {
        console.info("Erro ao salvar screenshot:");
        console.error(error);
      }
    },
  },
  computed: {
    ...mapGetters("alert", ["getAlerts", "getAlert"]),
    ...mapGetters("negotiation", {
      negotiation: "getNegotiation",
      getDealByProposalId: "getDealByProposalId",
    }),
    /** Retorna true se a proposta pode ser confirmada. */
    canConfirm() {
      if (!this.termsOfUse) {
        return false;
      }

      if (!this.email && !this.newEmail) {
        return false;
      }

      return true;
    },
    total_remaining_as_money() {
      return this.$options.filters.money(this.totalRemaining);
    },
    hasProposalConditions() {
      if (!this.proposal.id) {
        return false;
      }

      let conditions = store.getters["proposal/getConditions"](
        this.proposal.id
      );
      return conditions ? conditions.conditions != null : false;
    },
    /** Retorna o valor da parcela incluindo o IOF caso exista. */
    installmentValue() {
      let installmentIof = 0;

      if (!this.proposal) {
        return 0;
      }

      if (this.proposal.iof > 0) {
        installmentIof = this.proposal.iof / this.proposal.installments_count_1;
      }

      return this.proposal.installment_1 + installmentIof;
    },
    /** Indica se o resumo da proposta será exibido. */
    isDetailsVisible() {
      if (!this.proposal) {
        return false;
      }

      if (this.proposal.creditor_id == CreditorId.GERU) {
        return false;
      }

      if (this.extraProposalInfos) return true;

      if (this.regularizeDetails) return true;

      return (
        this.proposal.installments_count_1 || this.proposal?.discount_rate != 0
      );
    },

    /** Indica se o valor das parcelas deve ser exibido. */
    isInstallmentVisible() {
      if (!this.proposal.installment_1) {
        return false;
      }

      if (Proposal.isIrregularFlow(this.proposal)) {
        return false;
      }

      return true;
    },

    /** Indica se a quantidade de parcelas deve ser exibida. */
    isInstallmentsCountVisible() {
      if (!this.proposal.installments_count_1) {
        return false;
      }

      if (Proposal.isIrregularFlow(this.proposal)) {
        return false;
      }

      return true;
    },

    /** Indica se os detalhes do valor da parcela serão exibidos. */
    isInstallmentDetailsVisible() {
      if (!this.proposal) {
        return false;
      }

      if (this.proposal.payment_type != "INSTALLMENT") {
        return false;
      }

      if (!this.proposal.iof) {
        return false;
      }

      if (this.iofType != "divided") {
        return false;
      }

      return true;
    },
    /** Indica se a proposta é irregular. */
    isProposalIrregular() {
      if (!this.proposal) {
        return false;
      }

      if (
        this.proposal.installments_count_2 > 0 ||
        this.proposal.installments_count_3 > 0 ||
        this.proposal.installments_count_4 > 0 ||
        this.proposal.installments_count_5 > 0
      ) {
        return true;
      }

      return false;
    },
    /** Indica se o método de pagamento será exibido. */
    isPaymentMethodVisible() {
      return Deal.isPaymentMethodVisible(this.deal);
    },
    iofRegular() {
      const partial = this.proposal.iof / this.proposal.installments_count_1;

      return {
        total: this.proposal.iof,
        partial: partial,
        installment: this.proposal.installment_1 + partial,
      };
    },
    iofIrregular() {
      const totalInstallments =
        this.proposal.installments_count_1 +
        this.proposal.installments_count_2 +
        this.proposal.installments_count_3 +
        this.proposal.installments_count_4 +
        this.proposal.installments_count_5;
      const partial = this.proposal.iof / totalInstallments;

      return {
        total: this.proposal.iof,
        partial: partial,
        installments: totalInstallments,
      };
    },

    /** Retorna o valor total a ser pago na proposta. */
    totalAmountToPay() {
      if (this.proposal?.total_payment) return this.proposal.total_payment;
      return Proposal.calculateTotalAmountToPay(this.proposal);
    },
    getCreditorTermsLabel() {
      let proposalId = util.base64Decode(this.$route.query.id);
      let configPrefix = util.getPrefixByProposal(proposalId);
      let label = util.getConfig()[configPrefix + "terms_and_conditions_label"];
      if (label) return label;

      let profile = this.deal?.creditor_profile || this.deal?.creditor?.profile;
      let name = profile?.long_name || this.deal?.creditor?.name;

      if (this.hasDifferentTerms) {
        return `termos e condições d${
          profile?.gender == "MALE" ? "o" : "a"
        } ${name}`;
      }
      return "Termos de Uso";
    },
  },
};
</script>

<style scoped lang="scss">
.qj-footer {
  position: absolute !important;
  bottom: 0;
}

.qj-list {
  list-style-type: none;
  background: #f6f6f6;
  border-radius: 7px;
  padding: 0 !important;
}

.qj-iten-list-flex,
.qj-iten-list {
  padding: 10px;
  line-height: initial;
  .break-word {
    word-break: break-word;
  }
}

.qj-iten-list-flex {
  display: flex;
}

.qj-iten-list-flex b,
.qj-iten-list b {
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.qj-list-green {
  list-style-type: none;
  background: #cafce9;
  border-radius: 7px;
  padding: 10px 9px !important;
}

.qj-list-green li {
  display: flex;
  margin-top: 14px;
}

.qj-list-green li b {
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #000000;
}

.qj-icon-list {
  font-size: 20px;
  vertical-align: middle;
  line-height: 12px;
  margin-top: -3px;
  margin-left: 3px;
}

.qj-proposal-alias {
  font-size: 14px;
  padding: 12px 12px 0 !important;
  text-align: center;
}

.qj-proposal-down-payment {
  font-weight: 600;
}

.qj-proposal-irregular-flow {
  line-height: 15px;
  margin-bottom: 5px !important;

  .installment {
    font-weight: 800;
  }
}

.qj-proposal-conditions {
  text-align: center;
}

.qj-installment-details {
  .observation {
    font-size: 13px;
    line-height: 16px;
    margin: 0;
    max-width: 300px;
    padding: 4px 8px 12px;
  }

  .qj-iten-list-flex {
    font-size: 13px;
    padding: 6px 12px;

    p {
      font-size: 13px;
    }
  }
}

.form-email {
  padding: 0 16px;

  .qj-input {
    margin-top: 4px;
  }
}

.v-expansion-panel::before {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
    0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
}

.header-img {
  max-width: 135px;
  max-height: 45px;

  @media (min-width: 426px) {
    max-width: 190px;
    max-height: 50px;
  }
}

.color-blue {
  color: #2962ff !important;
}
</style>
