<template>
  <div v-if="deal">
    <v-fade-transition>
      <div v-show="dialog" class="qj-bg-dialog" @click="close"></div>
    </v-fade-transition>
    <v-dialog
      fullscreen
      scrollable
      persistent
      transition="dialog-bottom-transition"
      v-model="dialog"
    >
      <v-card>
        <v-card-title>
          <v-col cols="12" class="pt-0 text-center" style="position: relative">
            <h2 class="qj-h3">Detalhes do acordo</h2>
            <v-btn icon class="qj-btn-close-dialog" @click="close">
              <v-icon>far fa-times-circle</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text v-if="deal.status == 'RESOLVED'">
          <v-row>
            <v-col
              cols="12"
              class="justify-space-between align-center pt-2 pb-0"
              style="display: flex"
            >
              <img
                style="
                  margin-top: auto;
                  margin-bottom: auto;
                  max-height: 60px;
                  height: auto;
                  margin-right: 20px;
                "
                v-if="deal.creditor_profile"
                :src="deal.creditor_profile.logo_url"
                :alt="`Logo ${deal.creditor_profile.long_name}`"
              />
              <img
                style="
                  margin-top: auto;
                  margin-bottom: auto;
                  max-height: 60px;
                  height: auto;
                  margin-right: 20px;
                "
                v-else-if="deal.creditor.profile"
                :src="deal.creditor.profile.logo_url"
                :alt="`Logo ${deal.creditor.profile.long_name}`"
              />
              <div class="card-tag-state-wake-up tag-green">
                <v-icon
                  style="color: #000000; font-size: 14px; margin-right: 5px"
                  >far fa-check-circle</v-icon
                >
                <span
                  >{{ returnDealStatus() }}
                  <b>{{ lastPaymentPayed(deal) }}</b></span
                >
              </div>
            </v-col>
            <v-col cols="12" class="text-center pb-0">
              <p class="qj-p mb-0" v-if="!isParcial">
                Seu acordo com {{ creditorName(deal) }} está totalmente quitado.
                Agradecemos sua confiança!
              </p>
              <p
                class="qj-p mb-0"
                v-if="isParcial && deal.active_proposal.incentive"
                v-html="this.incentiveText(deal)"
              ></p>
            </v-col>
            <v-col cols="12" class="pt-2 pb-0">
              <ul class="qj-list">
                <li class="qj-iten-list pt-0">
                  <v-row>
                    <v-col cols="6" class="pb-2 pr-0">
                      <p class="qj-p mb-0">
                        Número do acordo: <br />
                        <b>{{ deal.external_ref }}</b>
                      </p>
                    </v-col>
                    <v-col
                      v-if="deal.contract_id"
                      cols="6"
                      class="text-right pb-2 pl-0"
                    >
                      <v-btn
                        class="rounded-lg qj-btn qj-bg-secondary-light"
                        @click="dialogDownloadContract = true"
                      >
                        Contrato</v-btn
                      >
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0" style="line-height: 8px">
                      <samp
                        style="
                          font-style: normal;
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 14px;
                          color: #000000;
                        "
                        >Utilize o número do acordo para tirar qualquer dúvida
                        junto ao credor.</samp
                      >
                    </v-col>
                  </v-row>
                </li>
              </ul>
            </v-col>
            <v-col class="my-2">
              <v-row class="align-center justify-center">
                <span class="row-title"> Informações da dívida </span>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <ul class="qj-list">
                <li
                  class="qj-iten-list border-b"
                  v-for="(operation, index) in deal.operations"
                  :key="index"
                >
                  <p class="value">
                    {{ operation.debit_value | money }}
                  </p>
                  <div>
                    <div v-if="operation.details">
                      <p class="qj-operation-details mt-2">
                        {{ operation.details }}
                      </p>
                    </div>

                    <b class="">{{ operation.product }}</b>
                    <br />

                    <span
                      v-if="operation.assignor"
                      class="qj-operation-assignor"
                    >
                      {{ operation.assignor }}
                    </span>
                  </div>
                </li>
                <li class="qj-iten-list mb-2">
                  <b class="value">{{ totalDebit(deal.operations) | money }}</b>
                  <p class="qj-p mb-0">
                    <v-icon
                      style="
                        color: #ff5050;
                        font-size: 18px;
                        margin-top: -3px;
                        margin-right: 11px;
                      "
                      >fas fa-dollar-sign</v-icon
                    >Saldo devedor
                  </p>
                </li>
              </ul>
            </v-col>
            <v-col v-if="detailsTextByAgreementType()">
              <div>
                <p class="remember-this">
                  {{ detailsTextByAgreementType() }}
                </p>
              </div>
            </v-col>
            <v-col v-else-if="isParcial" class="py-0">
              <span>
                <p class="remember-this">
                  Lembre-se que esta negociação não quitará totalmente sua
                  dívida. O objetivo deste acordo é apenas a
                  <span class="text-lowercase">{{
                    deal.active_proposal.incentive
                  }}</span
                  >.
                </p>
              </span>
            </v-col>
            <v-row class="px-4">
              <v-col class="pa-0 my-2 d-flex flex-column align-center">
                <span class="row-title"> Informações do acordo </span>
                <!-- <qj-proposal
                  class="grow align-self-stretch"
                  v-bind:proposal="deal.active_proposal"
                /> -->
              </v-col>
              <v-col v-if="deal.prePayment" cols="12">
                <ul class="qj-list qj-list-details">
                  <li v-if="deal.prePayment.card_number">
                    <span class="description">Cartão</span>
                    <span class="value d-flex align-center">
                      {{ deal.prePayment.card_number }}
                    </span>
                  </li>
                  <li v-if="deal.prePayment.card_brand">
                    <span class="description">Bandeira</span>
                    <span class="value d-flex align-center text-capitalize">
                      {{ deal.prePayment.card_brand }}
                      <img
                        class="card-brand ml-1"
                        :src="
                          require(`@/assets/img/svg/${deal.prePayment?.card_brand?.toLowerCase()}-logo.png`)
                        "
                        alt=""
                      />
                    </span>
                  </li>
                </ul>
              </v-col>
              <v-col
                cols="12"
                v-bind:id="'proposal-' + deal.active_proposal.id"
                class="row-title pt-0"
              >
                <div
                  class="qj-card-outline"
                  :class="returnProposalBoxClass(deal.active_proposal)"
                  :style="generateProposalBoxStyle(deal.active_proposal)"
                >
                  <ProposalCard
                    :deal="deal"
                    :proposal="
                      concludedParceleJaProposal || deal.active_proposal
                    "
                    :showAlias="true"
                    :do-show-next-button="false"
                  />
                </div>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <v-card-text
          v-else-if="
            deal.status == 'ABORTED' && deal.aborted_cause != 'ACTIVE_AGREEMENT'
          "
        >
          <v-row class="text-center">
            <v-col cols="12" class="pt-2 pb-0">
              <img
                class="qj-img"
                v-if="deal.creditor_profile"
                :src="deal.creditor_profile.logo_url"
                :alt="`Logo ${deal.creditor_profile.long_name}`"
              />
              <img
                class="qj-img"
                v-else-if="deal.creditor.profile"
                :src="deal.creditor.profile.logo_url"
                :alt="`Logo ${deal.creditor.profile.long_name}`"
              />
            </v-col>
            <v-col cols="12" class="mb-5">
              <div
                style="background: #f6f6f6; border-radius: 7px; padding: 10px"
                class="mb-2"
              >
                <p class="qj-p mb-0" style="font-weight: bold">
                  Seu acordo foi cancelado.
                </p>
              </div>
              <p class="qj-p mb-0">
                Para maiores esclarecimentos, entre em contato com nosso time de
                atendimento.
              </p>
            </v-col>
            <v-col cols="12" class="pt-2 pb-0">
              <p class="qj-p mb-0">Ficou com alguma dúvida?</p>
            </v-col>
            <v-col cols="12" class="pt-2 pb-0">
              <v-btn
                class="rounded-lg qj-btn qj-bg-primary"
                @click="dialogCallAttendant = true"
              >
                Falar com um atendente
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          v-else-if="
            deal.status == 'WAITING' || deal.aborted_cause == 'ACTIVE_AGREEMENT'
          "
        >
          <v-row class="text-center">
            <v-col cols="12" class="pt-2 pb-0">
              <img
                class="qj-img"
                v-if="deal.creditor_profile"
                :src="deal.creditor_profile.logo_url"
                :alt="`Logo ${deal.creditor_profile.long_name}`"
              />
              <img
                class="qj-img"
                v-else-if="deal.creditor.profile"
                :src="deal.creditor.profile.logo_url"
                :alt="`Logo ${deal.creditor.profile.long_name}`"
              />
            </v-col>
            <v-col cols="12" class="mb-5">
              <p class="qj-p mb-0">
                Não foi possível aprovar o seu acordo, pois você já possui um
                acordo ativo junto
                {{ deal.creditor.profile.gender == "MALE" ? "ao" : "a" }}
                {{ deal.creditor.profile.long_name }}.
              </p>
            </v-col>
            <v-col cols="12" class="pt-2 pb-0">
              <p class="qj-p mb-0">Ficou com alguma dúvida?</p>
            </v-col>
            <v-col cols="12" class="pt-2 pb-0">
              <v-btn
                class="rounded-lg qj-btn qj-bg-primary"
                @click="dialogCallAttendant = true"
              >
                Falar com um atendente
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog -->
    <DialogDownloadContract
      :id="deal.id"
      :dialog="dialogDownloadContract"
      @close="dialogDownloadContract = false"
    />
    <DialogCallAttendant
      :dialogCallAttendant="dialogCallAttendant"
      @closeCallAttendant="dialogCallAttendant = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import Deal from "@/modules/deal";
import util from "@/assets/js/util";
import DialogCallAttendant from "@/components/DialogCallAttendant";
import DialogDownloadContract from "../components/DialogDownloadContract";
import ProposalCard from "@/views/negotiation/components/proposalCard.vue";
import { mapGetters } from "vuex";

export default {
  props: ["dialog", "deal"],
  components: {
    DialogCallAttendant,
    DialogDownloadContract,
    ProposalCard,
  },
  data: () => ({
    dialogCallAttendant: false,
    dialogDownloadContract: false,
    alias: null,
    operations: null,
  }),
  mounted() {
    this.checkAliasType();
    this.getOperationName();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    totalDebit(operations) {
      return Deal.calculateDebts(operations);
    },
    checkAliasType() {
      let alias = this.deal.active_proposal?.alias?.replace(/\s+/g, " ").trim();
      this.alias = alias?.split("+");
    },
    getOperationName() {
      this.operations = this.deal.operations?.filter(
        (operation) => operation.active
      );
    },
    creditorName(deal) {
      const profile = deal?.creditor?.profile;
      if (!profile) return "";
      const prefix = profile.gender == "MALE" ? "o" : "a";
      return `${prefix} ${profile.long_name}`;
    },
    incentiveText(deal) {
      if (!deal?.active_proposal?.incentive)
        return "O valor do seu acordo foi quitado. Agradecemos sua confiança!";

      const article =
        deal.active_proposal.incentive.toUpperCase() ==
        "VALOR MÍNIMO DA FATURA EM ABERTO"
          ? "O"
          : "A";
      const settlement =
        deal.active_proposal.incentive.toUpperCase() ==
        "VALOR MÍNIMO DA FATURA EM ABERTO"
          ? "quitado"
          : "quitada";
      const creditor = this.creditorName(deal);

      if (deal.agreement_type == "REFINANCE") {
        return `O <strong>pagamento da entrada</strong> do seu acordo com <strong>${creditor}</strong> foi compensado. Agradecemos sua confiança! `;
      }

      return `${article} <b class="text-lowercase">${deal.active_proposal.incentive}</b> do seu acordo com <b>${creditor}</b> foi ${settlement}. 
                      Agradecemos sua confiança! `;
    },
    lastPaymentPayed(deal) {
      if (deal?.status !== "RESOLVED") return "";
      const lastPaymentPayed = JSON.parse(
        JSON.stringify(deal)
      ).payments.reverse();
      const date = lastPaymentPayed.find(
        (payment) => payment.status == "PAYED"
      ).payed_at;
      return moment(date).format("DD/MM/YYYY");
    },
    returnDealStatus() {
      return this.isParcial ? "Boleto pago" : "Acordo quitado";
    },
    returnProposalBoxClass(proposal) {
      if (!proposal) return "";
      if (proposal?.incentive == "Máximo parcelamento") {
        return "incentive-color-purple";
      } else if (proposal?.incentive == "Parcelamento máximo") {
        return "incentive-color-purple";
      } else if (proposal?.incentive == "Oferta sem juros") {
        return "incentive-color-gray";
      } else if (
        proposal.payment_type == "IN_CASH" &&
        proposal?.discount_rate > 0.1 &&
        !this.proposalOfferType &&
        proposal.id == this.proposals?.[0]?.id &&
        !proposal.incentive
      ) {
        return "incentive-color-pink";
      } else {
        return null;
      }
    },
    generateProposalBoxStyle(proposal) {
      if (this.returnProposalBoxClass(proposal) || !proposal?.incentive)
        return null;
      return {
        borderColor: util.stringToColor(proposal.incentive) ?? "#853500",
      };
    },
    detailsTextByAgreementType() {
      const config = "agreements-details-after-debt-text";
      const agreementType = this.deal?.agreement_type?.toLowerCase();
      const configName = `${this.deal?.creditor?.config_prefix}${agreementType}.${config}`;
      return this.getConfigurationByName(configName);
    },
  },
  computed: {
    ...mapGetters("negotiation", {
      getConfigurationByName: "getConfigurationByName",
    }),
    concludedParceleJaProposal() {
      if (
        this.deal.active_proposal?.parcele_ja_proposal &&
        this.deal.prePayment?.prepayment_status_code == 9
      ) {
        return this.deal.active_proposal?.parcele_ja_proposal;
      }
      return null;
    },
    isParcial() {
      let atualization = this.deal.tags_v2?.some(
        (tag) => tag.key == "atualizacao"
      );
      if (!atualization)
        atualization = this.deal?.tags?.split(",").includes("atualizacao");
      const isRefinance = this.deal.agreement_type == "REFINANCE";

      const isPartialType =
        this.deal.active_proposal.agreement_type == "REGULARIZE";
      return atualization || isPartialType || isRefinance;
    },
    proposal() {
      return this.deal.proposals.find((proposal) => {
        if (this.deal.prePayment)
          return proposal.id == this.deal.prePayment.proposal_id;
        return proposal.status === this.deal.status;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.qj-list-details .value {
  float: right;
  margin: 0;
}
.card-brand {
  width: 24px;
}
.qj-iten-list div b,
.qj-list-details .description {
  display: inline;
  font-style: normal;
  font-size: 14px;
  font-weight: 800;
  line-height: 17px;
  color: #212121 !important;
}
.qj-list,
.qj-expire-list {
  list-style-type: none;
  background: #f6f6f6;
  border-radius: 7px;
}

.qj-expire-list {
  padding: 3px 8px;
}

.qj-iten-list,
.qj-list-details li {
  padding: 10px;
  line-height: initial;
}

.qj-iten-list.border-b,
.qj-list-details li:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}
.card-tag-state-wake-up {
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  padding: 5px 8px 5px 6px;
  border-radius: 100px;
  width: max-content;
  height: auto;
  margin-left: auto;
}
@media (max-width: 320px) {
  .card-tag-state-wake-up .v-icon {
    font-size: 12px !important;
  }
}

.card-tag-state-wake-up.tag-green {
  font-weight: 400;
}

.card-tag-state-wake-up > :is(b, span) {
  font-size: 12px;
  vertical-align: middle;
}

.card-tag-state-wake-up.tag-green,
.card-tag-state-wake-up.tag-green b {
  background: #cafce9;
  color: #000000;
}

.qj-list {
  list-style-type: none;
  background: #f6f6f6;
  border-radius: 7px;
  padding: 0 !important;
}

.qj-iten-list {
  padding: 10px;
  line-height: initial;
}

.qj-iten-list.border-b {
  border-bottom: 1px solid #e5e5e5;
}

.qj-iten-list .value {
  float: right;
  margin: 0;
}

.qj-iten-list div {
  line-height: 0;
}

.qj-iten-list div b {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.qj-iten-list div span {
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
  color: #212121;
}

.row-title {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.remember-this {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 115.9%;
  color: #616161;
}
</style>
