import http from "@/plugins/axios";
const VERSION = "v1";
const RESOURCE = "contact";
const RESOURCE_URL = VERSION + '/' + RESOURCE

export default {
  get(id) {
    return http.api.get(RESOURCE_URL + '/' + id)
  },
  search(params) {
    return http.api.get(RESOURCE_URL, { params })
  },
  save(data) {
    if (data.id) {
      return http.api.patch(RESOURCE_URL + '/' + data.id, data)
    }
    return http.api.post(RESOURCE_URL, data)
  },
  saveWhatsApp(contactId, payload) {
    if (contactId) {
      return http.negotiationApi.post(`${RESOURCE_URL}/${contactId}/optin/whatsapp`, payload)
    }
    throw new Error('Erro ao salvar o whatsapp')
  },
  delete(id) {
    return http.api.delete(RESOURCE_URL + '/' + id)
  },
  /**
   * Serviço para atualizar valores.
   * 
   * @param {Object} payload { contact_id: 38046380, new_value: "jose.silva@gmail.com", send_verification: true }
   * @returns 
   */
  update(payload) {
    return http.api.post(RESOURCE_URL + '/_update_value', payload)
  },
  /**
   * Serviço para resolver inconsistencias.
   * 
   * @param {Number} id ID do contato.
   * @param {Object} payload { types: ["EMAIL_NOT_DELIVERED"] }
   * @returns 
   */
  resolveInconsistencies(id, payload) {
    return http.api.post(RESOURCE_URL + '/' + id + '/_resolve_inconsistencies', payload)
  },
  /**
   * Serviço para reenviar email de verificação.
   * 
   * @param {Number} id ID do contato.
   * @param {Object} payload { id: 65386612 }
   * @returns 
   */
  resend(id, payload) {
    return http.tasks.post(RESOURCE_URL + '/' + id + '/_send_verification', payload)
  },
  /**
 * Serviço para ativar whatsapp usando o hash id.
 * 
 * @param {String} hash_id
 * @returns 
 */
  optinWhatsapp(payload) {
    return http.negotiationApi.post(`${VERSION}/optin/whatsapp`, payload);
  },
  /**
   * Serviço para descadastrar do whatsapp usando o hash id.
   *
   * @param {Object} payload
   * @returns
   */
  optoutWhatsapp(payload) {
    return http.negotiationApi.delete(`${VERSION}/optin/whatsapp`, { data: payload });
  },
};